import React,{useEffect,useState} from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import ProductContentView from "./productContentView";
import RecipeItemContentView from "./recipeItemContentView";
import PopUp from "../../../Layouts/popUp";
import MaterialDataTable from "../../../Layouts/materialDataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ModalProducts from "../../modal-products";
import Select from 'react-select';
import Button from "../../atoms/Button";
import { useSelector } from "react-redux";

const styling = {
    container: {
        backgroundColor: '#eee',
        height: '100vh'
    },
    columns: {
        backgroundColor: '#fff',
        height: '100vh',
        overflowY: 'auto',
        paddingLeft: '0px',
        paddingRight: '0px',
        borderRight: '1px solid #eee'
    },
    headerInfoLabel: {
        backgroundColor: '#fff',
        textAlign: 'center',
        border: '1px solid rgb(213 213 213)'
    }
}
const defaultProductData = {
    id: 0,
    product_code: "",
    description: "",
    path_file: "",
    sub_category: "",
    total_price: 0
}
const generalData = {
    subCategories: [],
    units: [],
    recipeId: 0,
    recipes: {},
    recipeItems: [],
    recipesCompleted: []
}
const RecipeView = () => {
    const { productIdMd5 } = useParams(),
        [product,setProduct] = useState(defaultProductData),
        [generalDataRecipe,setGeneralDataRecipe] = useState(generalData),
        [showPopUp,setShowPopUp] = useState(false),
        [showQuoteModal,setShowQuoteModal] = useState(false),

        /** Material Modal */
        [modalProducts,setModalProducts] = useState(false),
        [modalProductsParam,setModalProductsParam] = useState({}),
        // eslint-disable-next-line no-unused-vars
        [products,setProducts] = useState([]),
        [customers,setCustomers] = useState([]),
        [suppliers,setSuppliers] = useState([]),
        [itemSelected,setItemSelected] = useState(null),
        [defaultCurrency,setDefaultCurrency] = useState(0),
        [defaultCurrencyLabel,setDefaultCurrencyLabel] = useState(''),

        quoteItems = useSelector((state)=>state.quoteItems.value)

        // navigate = useNavigate()
        
        ;
    const accentDecode = (tx) => {
        let rp = String(tx).toLocaleLowerCase();
        //
        rp = rp.replace(/&aacute;/g, 'á');
        rp = rp.replace(/&eacute;/g, 'é');
        rp = rp.replace(/&iacute;/g, 'í');
        rp = rp.replace(/&oacute;/g, 'ó');
        rp = rp.replace(/&uacute;/g, 'ú');
        rp = rp.replace(/&ntilde;/g, 'ñ');
        rp = rp.replace(/&uuml;/g, 'ü');
        //
        rp = rp.replace(/&Aacute;/g, 'Á');
        rp = rp.replace(/&Eacute;/g, 'É');
        rp = rp.replace(/&Iacute;/g, 'Í');
        rp = rp.replace(/&Oacute;/g, 'Ó');
        rp = rp.replace(/&Uacute;/g, 'Ú');
        rp = rp.replace(/&Ñtilde;/g, 'Ñ');
        rp = rp.replace(/&Üuml;/g, 'Ü');
        //
        return rp;
    }
    useEffect(async()=>{
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.get(process.env.REACT_APP_PATH_API + 'get/data/recipes/'+productIdMd5),
                data =  await resource.data;
                setProduct(data.product);
                setCustomers(data.customers);
                setSuppliers(data.suppliers);
                let unitArr = [];
                if ( data.units )
                    for (let i = 0; i < data.units.length; i++) {
                        const objUnit = data.units[i];
                        unitArr.push({
                            value: objUnit.value,
                            description: accentDecode(objUnit.description)
                        })
                    }

                setGeneralDataRecipe({
                    ...generalDataRecipe,
                    //subCategories: data.subCategories,
                    subCategories: data.materialSubCategories,
                    //units: data.units,
                    units: unitArr,
                    recipeId: data.recipe,
                    recipes: data.recipes,
                    recipeItems: data.recipeDetails,
                    recipesCompleted: data.recipesCompleted
                });
                setDefaultCurrency(data.defaultCurrency);
                setDefaultCurrencyLabel(data.defaultCurrencyLabel);
        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    },[]);
    const setRecipeItems = (items) => {
        setGeneralDataRecipe({
            ...generalDataRecipe,
            recipeItems: items
        })
    }
    const openArticleModel = async () => {
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/product/info',{
                    token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                    isMaterial: true
                }),
                data = await resource.data;
            setModalProductsParam(data);
            setModalProducts(true);
        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    const generateQuotation = async () => {
        console.log('quoteItems>>',quoteItems);
        console.log('<<>>',itemSelected);
        // try{
        //     const resource = await axios.post('',{}),
        //         data = await resource.data;
        // } catch(error){
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Error',
        //         text: "Error Interno, favor contacte al administrador"
        //     });
        // }
    }
    return (
        <>
            <div style={{width: '100%',height: '100%',backgroundColor: '#eee'}}>
                <div className="container-fluid" style={styling.container}>
                    {/* <div className="row">
                        <div className="col-12" style={{padding:'0.5rem',paddingLeft:'2rem'}}>
                            <a 
                                href="#" 
                                className="navbar-brand" 
                                style={{fontSize: '14px'}}
                                onClick={(e)=>{e.preventDefault();navigate('/recipes')}}
                                >
                                <FontAwesomeIcon icon={faArrowLeft} /> Volver a lista
                            </a>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-8" style={styling.columns}>
                            <RecipeItemContentView                                 
                                setShowPopUp={setShowPopUp}
                                generalData={generalDataRecipe}
                                setGeneralDataRecipe={setGeneralDataRecipe}
                                setRecipeItems={setRecipeItems}
                                openArticleModel={openArticleModel}
                                product={product}
                                setProduct={setProduct}
                                setShowQuoteModal={setShowQuoteModal}
                                accentDecode={accentDecode}
                            />
                        </div>
                        <div className="col-4" style={styling.columns}>
                            <ProductContentView 
                                product={product} 
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ModalProducts 
                show={modalProducts} 
                params={modalProductsParam} 
                handle={setModalProducts} 
                handleProducts={setProducts}
                defaultCurrency={defaultCurrency}
                defaultCurrencyLabel={defaultCurrencyLabel}
                isMaterial={true}
                suppliers={suppliers}
                 />
            <PopUp
                show={showPopUp}
                handle={setShowPopUp}
                content={<MaterialDataTable handlePopUp={setShowPopUp} handleProduct={setProduct} />}
            />
            <PopUp
                show={showQuoteModal}
                handle={setShowQuoteModal}
                tittle="Crear cotización"
                fullScreen={false}
                content={
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <label style={{marginBottom: '6px'}}>Selecciona el cliente para esta cotización</label>
                                <Select
                                    options={customers}                         
                                    className="form-control select-styling form-select-sm" 
                                    value={itemSelected}
                                    defaultValue={itemSelected} 
                                    onChange={setItemSelected}
                                />
                            </div>
                        </div>
                    </div>
                }
                buttons={
                    <Button 
                        type="button"
                        className="btn-primary btn-sm"
                        // eslint-disable-next-line react/prop-types
                        onClickInput={generateQuotation}
                        contentButton={
                            <>
                                <FontAwesomeIcon icon={faPlus} /> Procesar Cotización
                            </>
                        }
                    />
                }
            />
        </>
    )
}
export default RecipeView;