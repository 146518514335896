import React, {useState, useEffect} from 'react';
import { faArrowLeft, faEdit, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import SearchProductInput from '../Components/atoms/SearchProductInput';
import ModalProducts from '../Components/modal-products';
import axios from 'axios';
import { Button, ListGroup, Modal } from 'react-bootstrap';
const columns = [
    {
        name: 'ACTUALIZAR PRODUCTO',
        selector: row => row.updateProduct,
        center: true
    },
    {
        name: 'CÓDIGO',
        selector: row => row.product_code,
        sortable: true,
        center: true
    },
    {
        name: 'PRODUCTO',
        selector: row => row.product_des,
        sortable: true,
        center: true
    },
    {
        name: 'CATEGORÍA',
        selector: row => row.sub_category_des,
        sortable: true,
        center: true
    },
    {
        name: 'PRECIO',
        selector: row => row.total_price_formated,
        right: true
    },
    {
        name: 'INVENTARIO',
        selector: row => row.use_inventory_label,
        center: true
    }    
];
const paginationOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos'
};
const ItemMaster = () => {
    const [loadingTable,setLoadingTable] = useState(false),
        [dataTableData,setDataTableData] = useState([]),

        /** Product Modal */
        [modalProducts,setModalProducts] = useState(false),
        [modalProductsParam,setModalProductsParam] = useState({}),
        [products,setProducts] = useState([]),
        [defaultCurrency,setDefaultCurrency] = useState(0),
        [defaultCurrencyLabel,setDefaultCurrencyLabel] = useState(''),

        [itemProductInfo,setItemProductInfo] = useState(null),
        [isMaterialRegistering,setIsMaterialRegistering] = useState(false),
        [productMenuOption,setProductMenuOption] = useState(false);
    useEffect(()=>{
        searchItemData();
    },[]);
    useEffect(()=>{
        let data = products;
        setIconForData(data);
        setDataTableData(data);
    },[products]);
    const openArticleModel = async () => {
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/product/info',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK')
            }),
                data = await resource.data;
            setModalProductsParam(data);
            setIsMaterialRegistering(false);
            setModalProducts(true);
        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    const openMaterialModel = async () => {
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/product/info',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                isMaterial: true
            }),
                data = await resource.data;
            setModalProductsParam(data);
            setIsMaterialRegistering(true);
            setModalProducts(true);            
        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    const openProductMenu = async (element) => {
        const id = parseInt(element.currentTarget.getAttribute("id"));
        switch(id){
            case 1:
                openMaterialModel();            
                break;
            case 2:
                openArticleModel();
                break;    
        }
    }
    const searchItemData = async() => {
        setLoadingTable(true);
        try{
            // get/datatable/products/finished
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/datatable/item/master',{token: localStorage.getItem('OSHEN-LOC-STR-TK')}),
                resourceData = resource.data,
                data = (resourceData.dataTable!==undefined&&resourceData.dataTable!==null)? resourceData.dataTable : [] ;

            for(let i=0;i<data.length;i++){
                data[i].updateProduct = <FontAwesomeIcon id={parseInt(data[i].product_id)} icon={faEdit} onClick={itemForUpdate} style={{fontSize: '18px',fontWeight: 'bold',cursor: 'pointer',color: '#0d6efd'}} />
                data[i].chooseIcon = <FontAwesomeIcon id={parseInt(data[i].product_id)} icon={faPlusCircle} onClick={selectItem} style={{fontSize: '18px',fontWeight: 'bold',cursor: 'pointer',color: '#349d12'}} />
                data[i].total_price_formated = data[i].total_price===null ? '-' : data[i].total_price_formated == "$0.00" ? "Multi Precio" : data[i].total_price_formated;
            }
            setDataTableData(data);
            setDefaultCurrency(resourceData.defaultCurrency);
            setDefaultCurrencyLabel(resourceData.defaultCurrencyLabel);
            setLoadingTable(false);
        } catch(error){
            setLoadingTable(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    const itemForUpdate = async (input) => {
        const id = input.currentTarget.getAttribute('id');
        try{            
            /** CONSULT PRODUCT DATA */
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/product/by/id',{id:id,token: localStorage.getItem('OSHEN-LOC-STR-TK')}),
                data = await resource.data;
            let pricingArr = [];
            if(data.objPricing){
                for(let cnt=0;cnt<data.objPricing.length;cnt++){
                    const OPA = data.objPricing[cnt],
                        pricingArrData = {
                            id: parseInt(OPA.id),
                            currency: parseInt(OPA.currency_id),
                            price:parseFloat(OPA.total_price),
                            unit:parseInt(OPA.unit_id),
                            supplierValues:{}
                        };
                    pricingArr.push(pricingArrData);
                }
            }
            // eslint-disable-next-line react/prop-types
            const /*checkSupplierValues = {},            */
            product = {
                id: parseInt(data.product_id),
                code: data.product_code,
                description: data.product_des,
                subCategory: parseInt(data.sub_category_id),
                inventory: data.use_inventory=='t'?true:false,
                isCosting: data.is_costing,
                file: data.path_file,
                pricing: pricingArr/*[{
                    id: parseInt(data.objPricing.id),
                    currency: parseInt(data.objPricing.currency_id),
                    price:parseFloat(data.objPricing.total_price),
                    unit:parseInt(data.objPricing.unit_id),
                    supplierValues:checkSupplierValues
                }]*/,
                taxes: data.arrTaxes,
                inventories: [{
                    id: data.objInventory!==null ? parseInt(data.objInventory.id) : 0,
                    location: data.objInventory!==null ? parseInt(data.objInventory.location_id) : 0,
                    qty: data.objInventory!==null ? parseFloat(data.objInventory.product_inventory_qty) : 0,
                    unit: data.objInventory!==null ? parseInt(data.objInventory.unit_id) : 0
                }],
                discounts: {
                    id: data.objDiscount!==null ? parseInt(data.objDiscount.id) : 0,
                    currency: data.objDiscount!==null ? parseInt(data.objDiscount.currency_id) : 0,
                    percent: data.objDiscount!==null ? parseFloat(data.objDiscount.percent) : 0,
                    amount: data.objDiscount!==null ? parseFloat(data.objDiscount.amount) : 0
                }
            };

            if(data.for_sale == 'f')
                setIsMaterialRegistering(true);

            setModalProductsParam(data.itemGeneralInformation);
            // eslint-disable-next-line react/prop-types
            setItemProductInfo(product);
            // eslint-disable-next-line react/prop-types
            setModalProducts(true);

        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }        
    }
    const selectItem = async (input) => {
        const id = input.currentTarget.getAttribute('id');
        if(id){
            try{
                // eslint-disable-next-line no-undef
                const resource = await axios.post(process.env.REACT_APP_PATH_API + 'recipe/open', {
                    token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                    productId: id
                }),
                data = resource.data;
                if(!data.status){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Advertencia',
                        text: data.msg
                    });
                    return;
                }
            } catch(error){
                Swal .fire({
                    icon: 'error',
                    title: 'Error',
                    text: "Error Interno, favor contacte al administrador"
                });
            }
        }
    }
    const setIconForData = (data) => {
        for(let i=0;i<data.length;i++){
            data[i].updateProduct = <FontAwesomeIcon id={parseInt(data[i].product_id)} icon={faEdit} onClick={itemForUpdate} style={{fontSize: '18px',fontWeight: 'bold',cursor: 'pointer',color: '#0d6efd'}} />
            data[i].chooseIcon = <FontAwesomeIcon id={parseInt(data[i].product_id)} icon={faPlusCircle} onClick={selectItem} style={{fontSize: '18px',fontWeight: 'bold',cursor: 'pointer',color: '#349d12'}} />
            data[i].total_price_formated = data[i].total_price_formated == "$0.00" ? "Multi Precio" : data[i].total_price_formated;
        }
        return data;
    }
    return (
        <>
            <div className='container-fluid'>
                <div className="row">
                        <div className="col-12" style={{padding:'0.5rem',paddingLeft:'2rem'}}>
                            <a 
                                // eslint-disable-next-line no-undef
                                href={process.env.REACT_APP_PATH_APP}
                                className="navbar-brand" 
                                style={{fontSize: '14px'}}
                                >
                                <FontAwesomeIcon icon={faArrowLeft} /> Retornar a la aplicación
                            </a>
                        </div>
                </div>
                <div className="card">
                    <div className="card-header">
                        Maestro de articulos
                    </div>
                    <div className="card-body">
                        <div className='row'>
                            <div className='col-md-4'>
                                <SearchProductInput 
                                    fn={setProducts}
                                    loadingFn={setLoadingTable}
                                    resetProducts={searchItemData}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            {/* <div className="col-6 d-md-flex justify-content-md-start">
                                <button type="button" className="btn btn-success btn-sm" onClick={openMaterialModel}><FontAwesomeIcon icon={faPlus} /> Registro/Actualización Materia Prima</button>
                            </div> */}
                            <div className="col-12 d-md-flex justify-content-md-end">                                
                                <button type="button" className="btn btn-success btn-sm" onClick={()=>setProductMenuOption(true)}><FontAwesomeIcon icon={faPlus} /> Nuevo Producto</button>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 table-responsive'>
                                <DataTable 
                                    columns={columns}
                                    data={dataTableData}
                                    // title="Listado de productos"
                                    pagination
                                    paginationComponentOptions={paginationOptions}
                                    fixedHeader
                                    fixedHeaderScrollHeight='600'
                                    progressPending={loadingTable}
                                />
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            <ModalProducts 
                show={modalProducts} 
                params={modalProductsParam} 
                handle={setModalProducts} 
                handleProducts={setProducts}
                defaultCurrency={defaultCurrency}
                defaultCurrencyLabel={defaultCurrencyLabel} 
                itemProductInfo={itemProductInfo}
                isMaterial={isMaterialRegistering}
                />
            <Modal show={productMenuOption} onHide={()=>setProductMenuOption(false)}>
                <Modal.Header closeButton>
                <Modal.Title>Menú De Producto A Crear</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{padding: '0'}}>
                    <ListGroup>
                        <ListGroup.Item id={1} onClick={openProductMenu}>Registro de materia prima para recetas</ListGroup.Item>
                        <ListGroup.Item id={2} onClick={openProductMenu}>Registro de producto para venta</ListGroup.Item>
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={()=>setProductMenuOption(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ItemMaster;