import { faArrowLeft, faBuilding, faCalendar, /*faListOl,*/ faPaperclip, faPlus, faRefresh, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from "react";
import { Image } from "react-bootstrap";
import Swal from "sweetalert2";
const styling = {
    font: {
        fontSize: '12px',
        textTransform: 'initial',
        fontWeight: 'bold'
    },
    cFont: {
        fontSize: '9px',
        fontWeight: 'bold',
        textTransform: 'initial'
    },
    eFont: {
        fontSize: '9px',
        fontWeight: 'bold',
        textTransform: 'initial',
        marginLeft: '1rem'
    },
    fontBadge: {
        fontSize: '8px',
        fontWeight: 'bold',
        textTransform: 'initial'
    },
    td: {
        border: 'none'
    }
}
const UsrTask = (props) => {
    // const [isLoading,setIsLoading] = useState(false);    
    const showTask = async(element) => {
        const task = element.currentTarget.getAttribute('task');
        // eslint-disable-next-line react/prop-types
        props.setLoadingTaskInfo(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.get(process.env.REACT_APP_PATH_API + 'task/'+task+'/'+localStorage.getItem('OSHEN-LOC-STR-TK')),
                data = await resource.data;
            // eslint-disable-next-line react/prop-types
            props.setTaskInfo(data.task);
            // eslint-disable-next-line react/prop-types
            props.setUsrList(data.usrS);
            // eslint-disable-next-line react/prop-types
            props.setAllowApprove(data.authApprove);
            // eslint-disable-next-line react/prop-types
            props.setLoadingTaskInfo(false);
        } catch(error){
            // eslint-disable-next-line react/prop-types
            props.setLoadingTaskInfo(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    // const refreshTasks = async() =>{
    //     setIsLoading(true);
    //     try{
    //         // eslint-disable-next-line no-undef
    //         const resource = await axios.get(process.env.REACT_APP_PATH_API + 'tasks/'+localStorage.getItem('OSHEN-LOC-STR-TK')),
    //             data =  await resource.data;                
    //             // eslint-disable-next-line react/prop-types
    //             props.setTaskFA(data.tasksFA);
    //             setIsLoading(false);
    //     } catch(error){
    //         setIsLoading(false);
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Error',
    //             text: "Error Interno, favor contacte al administrador"
    //         });
    //     }
    // }
    // const refreshTasksOppenned = async() =>{
    //     setIsLoading(true);
    //     try{
    //         // eslint-disable-next-line no-undef
    //         const resource = await axios.get(process.env.REACT_APP_PATH_API + 'tasks/'+localStorage.getItem('OSHEN-LOC-STR-TK')),
    //             data =  await resource.data;                
    //             // eslint-disable-next-line react/prop-types
    //             props.setTaskOpenned(data.tasksOP);
    //             setIsLoading(false);
    //     } catch(error){
    //         setIsLoading(false);
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Error',
    //             text: "Error Interno, favor contacte al administrador"
    //         });
    //     }
    // }
    // const refreshLastTaks = async() =>{
    //     setIsLoading(true);
    //     try{
    //         // eslint-disable-next-line no-undef
    //         const resource = await axios.get(process.env.REACT_APP_PATH_API + 'tasks/'+localStorage.getItem('OSHEN-LOC-STR-TK')),
    //             data =  await resource.data;                
    //             // eslint-disable-next-line react/prop-types
    //             props.setLastTasks(data.lastTasks);
    //             setIsLoading(false);
    //     } catch(error){
    //         setIsLoading(false);
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Error',
    //             text: "Error Interno, favor contacte al administrador"
    //         });
    //     }
    // }
    return (
        <>
        <table className="table task-table-2">
            <thead>
                <tr>
                    <th>
                        <FontAwesomeIcon 
                            icon={faArrowLeft} 
                            style={{cursor:'pointer', marginRight: '40%'}}
                            // eslint-disable-next-line no-undef
                            onClick={()=>location.href=process.env.REACT_APP_PATH_APP}
                         />
                         <FontAwesomeIcon 
                            icon={faRefresh} 
                            style={{cursor:'pointer'}}
                            // eslint-disable-next-line no-undef
                            onClick={()=>location.reload()}
                         />
                    </th>
                    <th colSpan={2} className="text-warning" style={{textAlign: 'left'}}>
                        {
                            // eslint-disable-next-line react/prop-types
                            props.createPermission?
                            <>
                                {/* eslint-disable-next-line react/prop-types */}
                                <button type="button" className="btn btn-success btn-sm" style={{width: '100%',borderRadius: '20px'}} onClick={()=>props.fnTaskModal(true)}                                    
                                ><FontAwesomeIcon icon={faPlus} /> Crear Tarea</button>
                            </>
                            :null
                        }
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colSpan={3} style={styling.td}>
                        <Image 
                            style={{width: '72px'}}
                            fluid={true}
                            roundedCircle={true}
                            thumbnail={true}
                            // eslint-disable-next-line no-undef, react/prop-types
                            src={process.env.REACT_APP_PATH_APP+props.usr.path_file}
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan={3} style={{...styling.td,...styling.font,...{padding: 0}}}>
                        {/* eslint-disable-next-line react/prop-types */}
                        {props.usr.personal_name}
                    </td>
                </tr>
                <tr>
                    <td colSpan={3} style={{...styling.td,...styling.font,...{padding: 0}}} className="text-muted">
                        {/* eslint-disable-next-line react/prop-types */}
                        {props.usr.user_name}
                    </td>
                </tr>
                <tr>
                    <td colSpan={3} style={{...styling.td,...styling.font,...{padding: 0}}} className="text-muted">
                        {/* eslint-disable-next-line react/prop-types */}
                        {props.usr.bp_email}
                    </td>
                </tr>
                {/* <tr>
                    <td colSpan={3}>PROGRESS BAR</td>
                </tr>
                <tr>
                    <td>REQUEST TASK</td>
                    <td>TASK TO DO</td>
                    <td>COMPLETE</td>
                </tr> */}
            </tbody>
        </table>
        {
            // eslint-disable-next-line react/prop-types
            (props.taskFA!==undefined&&props.taskFA!==null&&props.taskFA.length)?
            <>
                <table className="table task-table-2 hover-table">
                    <thead>
                        <tr>
                            <th colSpan={3} className="text-primary" style={{...styling.font,...{textTransform: 'uppercase',textAlign: 'center',textDecoration: 'underline'}}}>
                                {
                                    // isLoading?<Spinner animation="border" role="status" size="sm" />
                                    // :<FontAwesomeIcon icon={faRefresh} style={{cursor: 'pointer'}} onClick={refreshTasks} />
                                } A la espera de tu aprobación
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {                    
                            // eslint-disable-next-line react/prop-types
                            (props.taskFA.length)?
                                // eslint-disable-next-line react/prop-types
                                props.taskFA.map((e,i)=>{
                                    return (
                                        <tr key={i} task={e.id} style={{cursor: 'pointer'}} onClick={showTask}>
                                            <td style={{width: '20%',borderBottom: 'none'}}>
                                                <Image 
                                                    style={{width: '42px'}}
                                                    fluid={true}
                                                    roundedCircle={true}
                                                    thumbnail={true}
                                                    // eslint-disable-next-line no-undef, react/prop-types
                                                    src={(props.viewer==1)?process.env.REACT_APP_PATH_APP+e.path_file_assign:process.env.REACT_APP_PATH_APP+e.path_file}
                                                />
                                            </td>
                                            <td style={{width: '80%',borderBottom: 'none'}} className="text-align-left">
                                                <span style={styling.fontBadge} className={`badge text-bg-${e.status_i}`}>{e.status_des}</span><br />
                                                <label style={{...styling.font,...{cursor: 'pointer'}}}>
                                                    {
                                                        e.files.length?
                                                        <FontAwesomeIcon icon={faPaperclip} />
                                                        :null
                                                    }&nbsp;&nbsp;
                                                    {'#'+e.id+' '+e.subject}
                                                </label><br />
                                                <span className="text-muted" style={styling.eFont}><FontAwesomeIcon icon={faBuilding} />&nbsp;{e.dep}</span>
                                                {/* <span className="text-muted" style={styling.eFont}><FontAwesomeIcon icon={faListOl} />&nbsp;{e.cond}</span> */}
                                                <span className="text-muted" style={styling.eFont}><FontAwesomeIcon icon={faCalendar} />&nbsp;{e.createdAtFormatted}</span>
                                                <br /><span className="text-muted" style={styling.eFont}><FontAwesomeIcon icon={faUser} />&nbsp;{e.personal_name}</span>
                                            </td>                                            
                                        </tr>
                                    )
                                })
                            :<tr><td colSpan={3} style={styling.font}>Datos no encontrados</td></tr>
                        }                
                    </tbody>
                </table>
            </>
            :null
        }
        {
            // eslint-disable-next-line react/prop-types
            (props.taskOpenned!==undefined&&props.taskOpenned!==null&&props.taskOpenned.length)?
            <>
                <table className="table task-table-2 hover-table">
                    <thead>
                        <tr>
                            <th colSpan={3} className="text-info" style={{...styling.font,...{textTransform: 'uppercase',textAlign: 'center',textDecoration: 'underline'}}}>
                                {
                                    // isLoading?<Spinner animation="border" role="status" size="sm" />
                                    // :<FontAwesomeIcon icon={faRefresh} style={{cursor: 'pointer'}} onClick={refreshTasksOppenned} />
                                } Solicitudes abiertas
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {                    
                            // eslint-disable-next-line react/prop-types
                            (props.taskOpenned.length)?
                                // eslint-disable-next-line react/prop-types
                                props.taskOpenned.map((e,i)=>{
                                    return (
                                        <tr key={i} task={e.id} style={{cursor: 'pointer'}} onClick={showTask}>
                                            <td style={{width: '20%',borderBottom: 'none'}}>
                                                <Image 
                                                    style={{width: '42px'}}
                                                    fluid={true}
                                                    roundedCircle={true}
                                                    thumbnail={true}
                                                    // eslint-disable-next-line no-undef, react/prop-types
                                                    src={(props.viewer==1)?process.env.REACT_APP_PATH_APP+e.path_file_assign:process.env.REACT_APP_PATH_APP+e.path_file}
                                                />
                                            </td>
                                            <td style={{width: '80%',borderBottom: 'none'}} className="text-align-left">
                                                <span style={styling.fontBadge} className={`badge text-bg-${e.status_i}`}>{e.status_des}</span><br />
                                                <label style={{...styling.font,...{cursor: 'pointer'}}}>
                                                    {
                                                        e.files.length?
                                                        <FontAwesomeIcon icon={faPaperclip} />
                                                        :null
                                                    }&nbsp;&nbsp;
                                                    {'#'+e.id+' '+e.subject}
                                                </label><br />
                                                <span className="text-muted" style={styling.eFont}><FontAwesomeIcon icon={faBuilding} />&nbsp;{e.dep}</span>
                                                {/* <span className="text-muted" style={styling.eFont}><FontAwesomeIcon icon={faListOl} />&nbsp;{e.cond}</span> */}
                                                <span className="text-muted" style={styling.eFont}><FontAwesomeIcon icon={faCalendar} />&nbsp;{e.createdAtFormatted}</span>
                                                <br /><span className="text-muted" style={styling.eFont}><FontAwesomeIcon icon={faUser} />&nbsp;{e.personal_name}</span>
                                            </td>                                            
                                        </tr>
                                    )
                                })
                            :<tr><td colSpan={3} style={styling.font}>Datos no encontrados</td></tr>
                        }
                    </tbody>
                </table>
            </>
            :null
        }
        {
            // eslint-disable-next-line react/prop-types
            (props.lastTasks!==undefined&&props.lastTasks!==null&&props.lastTasks.length)?
            <>
                <table className="table task-table-2 hover-table">
                    <thead>
                        <tr>
                            <th colSpan={3} style={{...styling.font,...{textTransform: 'uppercase',textAlign: 'center',textDecoration: 'underline'}}}>
                                {
                                    // isLoading?<Spinner animation="border" role="status" size="sm" />
                                    // :<FontAwesomeIcon icon={faRefresh} style={{cursor: 'pointer'}} onClick={refreshLastTaks} />
                                } Tus Ultimas Tareas
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {                    
                            // eslint-disable-next-line react/prop-types
                            (props.lastTasks!==undefined&&props.lastTasks!==null&&props.lastTasks.length)?
                                // eslint-disable-next-line react/prop-types
                                props.lastTasks.map((e,i)=>{
                                    return (
                                        <tr key={i} task={e.id} style={{cursor: 'pointer'}} onClick={showTask}>
                                            <td style={{width: '20%',borderBottom: 'none'}}>
                                                <Image 
                                                    style={{width: '42px'}}
                                                    fluid={true}
                                                    roundedCircle={true}
                                                    thumbnail={true}
                                                    // eslint-disable-next-line no-undef, react/prop-types
                                                    src={(props.viewer==1)?process.env.REACT_APP_PATH_APP+e.path_file_assign:process.env.REACT_APP_PATH_APP+e.path_file}
                                                />
                                            </td>
                                            <td style={{width: '80%',borderBottom: 'none'}} className="text-align-left">
                                                <span style={styling.fontBadge} className={`badge text-bg-${e.status_i}`}>{e.status_des}</span><br />
                                                <label style={{...styling.font,...{cursor: 'pointer'}}}>
                                                    {
                                                        e.files.length?
                                                        <FontAwesomeIcon icon={faPaperclip} />
                                                        :null
                                                    }&nbsp;&nbsp;
                                                    {'#'+e.id+' '+e.subject}
                                                </label><br />
                                                <span className="text-muted" style={styling.eFont}><FontAwesomeIcon icon={faBuilding} />&nbsp;{e.dep}</span>
                                                {/* <span className="text-muted" style={styling.eFont}><FontAwesomeIcon icon={faListOl} />&nbsp;{e.cond}</span> */}
                                                <span className="text-muted" style={styling.eFont}><FontAwesomeIcon icon={faCalendar} />&nbsp;{e.createdAtFormatted}</span>
                                                <span className="text-muted" style={styling.eFont}><FontAwesomeIcon icon={faUser} />&nbsp;{e.personal_name}</span>
                                            </td>                                            
                                        </tr>
                                    )
                                })
                            :<tr><td colSpan={3} style={styling.font}>Datos no encontrados</td></tr>
                        }
                    </tbody>
                </table>
            </>
            :null
        }        

        </>
    )
}

export default UsrTask;