import React from "react";
import clsx from "clsx";
import Proptypes from 'prop-types';

const FormGroup = (props) => {    
    const {index, onChange, value, className, inputType, label, inputName, id, inputClassName, keyRef} = props;
    return (
        <div className={clsx('mb-3', className)}>
            <label className='form-label'>{label}</label>
            <input 
                type={inputType || 'text'}
                ref={keyRef}
                id={id}
                idx={index}
                keyid={inputName} 
                name={inputName} 
                className={clsx('form-control',inputClassName||'form-control-md')}
                onChange={onChange} 
                value={value}
                 />
        </div>
    )
}

FormGroup.propTypes = {
    index: Proptypes.number,
    onChange: Proptypes.func.isRequired,
    value: Proptypes.any,
    className: Proptypes.string,
    inputType: Proptypes.string,
    label: Proptypes.node.isRequired,
    inputName: Proptypes.string.isRequired,
    id: Proptypes.string,
    inputClassName: Proptypes.string,
    keyRef: Proptypes.any
}

export default FormGroup;