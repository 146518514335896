import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from "react";
import { Button, Modal, Table } from "react-bootstrap";
import Swal from "sweetalert2";

const ModalMultiPrice = (props) => {
    const selectPrice = async(element) => {
        const pricingId = element.currentTarget.getAttribute("id");
        // eslint-disable-next-line react/prop-types
        let productObject = {...props.product};
        // eslint-disable-next-line react/prop-types
        if(props.list){
            let pricingObj = null;
            // eslint-disable-next-line react/prop-types
            for(let counter=0;counter<props.list.length;counter++){
                // eslint-disable-next-line react/prop-types
                const obj = props.list[counter];
                // eslint-disable-next-line react/prop-types
                if(parseInt(obj.id)==parseInt(pricingId)){
                    pricingObj = obj;
                    break;
                }
            }
            try{
                // eslint-disable-next-line no-undef
                const res = await axios.post(process.env.REACT_APP_PATH_API + 'get/extra/info/by/pricing',{
                    token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                    productId: productObject.product_id,
                    subCategoryId: productObject.sub_category_id,
                    currencyId: pricingObj.currency_id,
                    totalPrice: pricingObj.total_price,
                    conditionId: productObject.condition_id,
                    unitId: pricingObj.unit_id
                }),
                datos = res.data;

                productObject.qty = 1;
                productObject.total_price = pricingObj.total_price;
                productObject.total_price_formated = pricingObj.total_price_formatted;
                productObject.amount = productObject.qty * pricingObj.total_price;                
                //productObject.total_discount = parseFloat(productObject.qty) * parseFloat(productObject.discountByPrice);
                productObject.unit_id = pricingObj.unit_id;
                productObject.unit_des = pricingObj.unit_des;
                productObject.earning_price = pricingObj.earning_price;

                productObject.taxes = datos.taxes;
                productObject.taxes_amount = datos.taxes_amount;
                productObject.total_amount_tax = productObject.qty * productObject.taxes_amount;
                productObject.total_price_with_taxes = datos.total_price_with_taxes;
                productObject.discounts = datos.discounts;
                productObject.discountByPrice = datos.discountByPrice;
                //productObject.total_discount = datos.total_discount;
                productObject.total_discount = parseFloat(productObject.qty) * parseFloat(datos.total_discount);
                productObject.item_qty_inv = datos.item_qty_inv;
                //productObject.total_amount = datos.total_amount;
                productObject.total_amount = ( parseFloat( productObject.amount ) + parseFloat( productObject.total_amount_tax ) ) - parseFloat( productObject.total_discount );
                productObject.base_price = pricingObj.base_price;                

                // eslint-disable-next-line react/prop-types
                let shoppingCartList = [...props.shoppingCartPos];
                shoppingCartList.push(productObject);
                // eslint-disable-next-line react/prop-types
                props.setShoppingCartPos(shoppingCartList);
                // eslint-disable-next-line react/prop-types
                props.handle(false);

            } catch(error){
                console.log('<<error>>',error);
                Swal.fire({
                    icon:'error',
                    title: 'Error interno',
                    text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
                });
            }            
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Precio no encontrado, favor contactar al administrador'
            });
        }
    }
    return(
        // eslint-disable-next-line react/prop-types
        <Modal size="md" show={props.show} onHide={()=>props.handle(false)} backdrop="static" keyboard={false}>
            <Modal.Header className="custom-modal-header" closeButton>
                <Modal.Title>Selecciona un precio para facturar</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{padding: 0}}>
                <Table striped bordered hover className="task-table">
                    <thead>
                        <tr>
                            <th>SELECCIONAR</th>
                            <th>PRECIO</th>
                            <th>UNIDAD</th>
                            <th>CONDICIÓN</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            // eslint-disable-next-line react/prop-types
                            props.list?
                            // eslint-disable-next-line react/prop-types
                            props.list.map((e,i)=>{
                                return (
                                    <tr key={i}>
                                        <td><FontAwesomeIcon id={e.id} icon={faCheckCircle} style={{fontSize: '18px',fontWeight: 'bold',cursor: 'pointer', color: "#15a513"}} onClick={selectPrice} /></td>
                                        <td>{e.total_price_formatted}</td>
                                        <td>{e.unit_des}</td>
                                        <td>{e.condition_des}</td>
                                    </tr>
                                )
                            })
                            :null
                        }                        
                    </tbody>
                </Table>                
            </Modal.Body>
            <Modal.Footer>
                {/* eslint-disable-next-line react/prop-types */}
                <Button variant="secondary" onClick={()=>props.handle(false)}>
                    Close
                </Button>                              
            </Modal.Footer>
        </Modal>
    );
}
export default ModalMultiPrice;