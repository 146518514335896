import React, {useEffect,useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import { faPlay, faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
const columns = [
    {
        name: 'ABRIR',
        selector: row => row.chooseIcon,
        center: true
    },
    {
        name: 'CANCELAR',
        selector: row => row.deleteIcon,
        center: true
    },
    {
        name: '#CITA',
        selector: row => row.id,
        sortable: true,
        center: true
    },
    {
        name: 'CLIENTE',
        selector: row => row.customer_name,
        sortable: true,
        center: true
    },
    {
        name: 'CORREO DEL CLIENTE',
        selector: row => row.customer_email,
        center: true
    },
    {
        name: 'TELÉFONO DEL CLIENTE',
        selector: row => row.bp_phone,
        center: true
    },
    {
        name: 'FECHA',
        selector: row => row.a_date,
        sortable: true,
        center: true
    },
    {
        name: 'ENCARGADO',
        selector: row => row.user_name,
        sortable: true,
        center: true
    },
    {
        name: 'ESTATUS',
        selector: row => row.status_des,
        right: true
    }    
];
const paginationOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos'
};
const AppointmentInAssists = (props) => {
    const [gridLoading,setGridLoading] = useState(false),
        [gridData,setGridData] = useState([]);
    useEffect(()=>{
        setGridLoading(true);
        // eslint-disable-next-line react/prop-types
        const data = props.resetAppointmentAssists;
        // eslint-disable-next-line react/prop-types
        let appointments = data.appointments;
        if(appointments)
        {
            // eslint-disable-next-line react/prop-types
            const inTurn = data.inTurn;
            // eslint-disable-next-line react/prop-types
            for(let i=0;i<appointments.length;i++){
                // eslint-disable-next-line react/prop-types
                if(inTurn.includes(parseInt(appointments[i].usr_attendant))){
                    // eslint-disable-next-line react/prop-types
                    appointments[i].chooseIcon = null;
                } else {
                    // eslint-disable-next-line react/prop-types
                    appointments[i].chooseIcon = <FontAwesomeIcon title="Abrir cita" id={parseInt(appointments[i].id)} icon={faPlay} onClick={putInPlay} style={{fontSize: '14px',fontWeight: 'bold',cursor: 'pointer',color: '#319f04'}} />
                }
                // eslint-disable-next-line react/prop-types
                appointments[i].deleteIcon = <FontAwesomeIcon id={parseInt(appointments[i].id)} icon={faTimes} onClick={props.cancel} style={{fontSize: '14px',fontWeight: 'bold',cursor: 'pointer',color: '#f00'}} />
            }
        }
        setGridData(appointments);
        setGridLoading(false);
    // eslint-disable-next-line react/prop-types
    },[props.resetAppointmentAssists])
    useEffect(async()=>{
        setGridLoading(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'appointment/assists/list',{token: localStorage.getItem('OSHEN-LOC-STR-TK')}),
            data = await resource.data;
            let appointments = data.appointments;
            if(appointments)
            {
                const inTurn = data.inTurn;
                for(let i=0;i<appointments.length;i++){
                    if(inTurn.includes(parseInt(appointments[i].usr_attendant))){
                        appointments[i].chooseIcon = null;
                    } else {
                        appointments[i].chooseIcon = <FontAwesomeIcon title="Abrir cita" id={parseInt(appointments[i].id)} icon={faPlay} onClick={putInPlay} style={{fontSize: '14px',fontWeight: 'bold',cursor: 'pointer',color: '#319f04'}} />
                    }
                    // eslint-disable-next-line react/prop-types
                    appointments[i].deleteIcon = <FontAwesomeIcon id={parseInt(appointments[i].id)} icon={faTimes} onClick={props.cancel} style={{fontSize: '14px',fontWeight: 'bold',cursor: 'pointer',color: '#f00'}} />
                }
            }
            setGridData(appointments);
            setGridLoading(false);
        } catch(error){
            setGridLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    },[]);
    const resetAssistsData = (appointments,inTurn) => {
        if(appointments)
        {
            for(let i=0;i<appointments.length;i++){
                if(inTurn.includes(parseInt(appointments[i].usr_attendant))){
                    appointments[i].chooseIcon = null;
                } else {
                    appointments[i].chooseIcon = <FontAwesomeIcon title="Abrir cita" id={parseInt(appointments[i].id)} icon={faPlay} onClick={putInPlay} style={{fontSize: '14px',fontWeight: 'bold',cursor: 'pointer',color: '#319f04'}} />
                }
                // eslint-disable-next-line react/prop-types
                appointments[i].deleteIcon = <FontAwesomeIcon id={parseInt(appointments[i].id)} icon={faTimes} onClick={props.cancel} style={{fontSize: '14px',fontWeight: 'bold',cursor: 'pointer',color: '#f00'}} />
            }
        }
        setGridData(appointments);
    }
    const putInPlay = (input) => {
        const id = input.currentTarget.getAttribute('id');
        setInPlay(id);
    }
    const setInPlay = async(id) => {
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'turn/open',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                data: {id}
            }),
            data = await resource.data;
            if(!data.status){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.message
                });
                return;
            }
            Swal.fire({
                icon: 'success',
                title: 'Operación Exitosa',
                text: data.message
            }).then(()=>{
                resetAssistsData(data.appointmentAssists,data.inTurn);
            });
        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    return (
        <div className='container-fluid'>
            <div className='row justify-content-center' style={{marginTop: '2.5rem'}}>
                {/* eslint-disable-next-line react/prop-types */}
                <div className='col-12 table-responsive' style={props.styling.headerInfoLabel}>
                    <DataTable
                        columns={columns}
                        data={gridData}
                        title="Citas en espera de asistencia"
                        pagination
                        paginationComponentOptions={paginationOptions}
                        fixedHeader
                        fixedHeaderScrollHeight='600'
                        // eslint-disable-next-line react/prop-types
                        progressPending={gridLoading}
                    />
                </div>
            </div>
        </div>
    )
}

export default AppointmentInAssists;