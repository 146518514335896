// import { faHome, faRefresh } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
const Header = () => {
    return (<h3 style={{marginTop: 0, marginBottom: 0}}></h3>
        // <nav className="navbar navbar-expand-lg bg-light">
        //     <div className="container-fluid">
        //         <a className="navbar-brand" href={process.env.REACT_APP_PATH_APP}>Nueva Plataforma</a>
        //             <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        //                 <span className="navbar-toggler-icon"></span>
        //             </button>
        //         <div className="collapse navbar-collapse" id="navbarText">
        //         <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        //             <li className="nav-item">
        //                 <a className="nav-link active" aria-current="page" href={process.env.REACT_APP_PATH_APP}><FontAwesomeIcon icon={faHome} /> Inicio</a>
        //             </li>
        //             <li className="nav-item">
        //                 <a className="nav-link" href="#" onClick={(e)=>{e.preventDefault();location.reload();}}><FontAwesomeIcon icon={faRefresh} /> Refrescar interfaz</a>
        //             </li>                    
        //         </ul>
        //         <span className="navbar-text">
        //             A pesar de que la interfaz se modificó, todas las funcionalidades mejoraron.
        //         </span>
        //         </div>
        //     </div>
        // </nav>
    )
}
export default Header;