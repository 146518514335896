import React,{useEffect, useState} from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faSearch, faClose } from "@fortawesome/free-solid-svg-icons";
const columns = [
    {
        name: 'CÓDIGO',
        selector: row => row.product_code,
        sortable: true,
        center: true
    },
    {
        name: 'PRODUCTO',
        selector: row => row.product_des,
        sortable: true,
        center: true
    },
    {
        name: 'CATEGORÍA',
        selector: row => row.sub_category_des,
        sortable: true,
        center: true
    },
    {
        name: 'COSTO',
        selector: row => row.total_price_formated,
        right: true
    },
    {
        name: 'INVENTARIO',
        selector: row => row.use_inventory_label,
        center: true
    },
    {
        name: 'SELECCIONAR',
        selector: row => row.chooseIcon,
        center: true
    }
];
const paginationOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos'
};

const MaterialDataTable = (props) => {
    const [dataTableData,setDataTableData] = useState([]),
        [loadingTable,setLoadingTable] = useState(false),
        [criteria,setCriteria] = useState('');
    useEffect(async()=>{
        setLoadingTable(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/datatable/materials',{token: localStorage.getItem('OSHEN-LOC-STR-TK')}),
                data = resource.data;
            for(let i=0;i<data.length;i++){
                data[i].chooseIcon = <FontAwesomeIcon id={parseInt(data[i].product_id)} icon={faArrowRightLong} onClick={selectItem} style={{fontSize: '14px',fontWeight: 'bold',cursor: 'pointer'}} />
            }
            setDataTableData(data);
            setLoadingTable(false);
        } catch(error){
            setLoadingTable(false);
            console.error('Internal Error',error);
        }
    },[]);
    useEffect(()=>{
        if(criteria.length<1){
            search();
        }
    },[criteria]);
    const keyChangeCriteria = (input) => {
        const value = input.target.value;
        setCriteria(value);
    }
    const onKeyUpCriteria = (input) => {
        if(input.key=='Enter'){
            search();
        }
    }
    const search = async () => {
        if(criteria)
        {
            setLoadingTable(true);
            try{
                // eslint-disable-next-line no-undef
                const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/datatable/products/by/criteria',{criteria: criteria,token: localStorage.getItem('OSHEN-LOC-STR-TK')}),
                    data = resource.data;            
                for(let i=0;i<data.length;i++){
                    data[i].chooseIcon = <FontAwesomeIcon id={parseInt(data[i].product_id)} icon={faArrowRightLong} onClick={selectItem} style={{fontSize: '14px',fontWeight: 'bold',cursor: 'pointer'}} />
                }
                setDataTableData(data);
                setLoadingTable(false);
            } catch(error){
                setLoadingTable(false);
                console.error('Internal Error',error);
            }
        }
    }
    const selectItem = async (input) => {
        const id = input.currentTarget.getAttribute('id');
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/product/by/id',{id:id}),
                data = resource.data;
            const product = {
                id: parseInt(data.product_id),
                code: data.product_code,
                description: data.product_des,
                subCategory: parseInt(data.sub_category_id),
                inventory: data.use_inventory=='t'?true:false,
                file: data.path_file,
                pricing: [{
                    id: parseInt(data.objPricing.id),
                    currency: parseInt(data.objPricing.currency_id),
                    price:parseFloat(data.objPricing.total_price),
                    unit:parseInt(data.objPricing.unit_id)
                }],
                taxes: data.arrTaxes,
                inventories: [{
                    id: data.objInventory!==null ? parseInt(data.objInventory.id) : 0,
                    location: data.objInventory!==null ? parseInt(data.objInventory.location_id) : 0,
                    qty: data.objInventory!==null ? parseFloat(data.objInventory.product_inventory_qty) : 0,
                    unit: data.objInventory!==null ? parseInt(data.objInventory.unit_id) : 0
                }],
                discounts: {
                    id: data.objDiscount!==null ? parseInt(data.objDiscount.id) : 0,
                    currency: data.objDiscount!==null ? parseInt(data.objDiscount.currency_id) : 0,
                    percent: data.objDiscount!==null ? parseFloat(data.objDiscount.percent) : 0,
                    amount: data.objDiscount!==null ? parseFloat(data.objDiscount.amount) : 0
                }
            };
            // eslint-disable-next-line react/prop-types
            props.handleProduct(product);
            // eslint-disable-next-line react/prop-types
            props.handlePopUp(false);
        } catch(error){
            console.error('Internal Error',error);
        }
    }
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-4'>
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" placeholder="Criterio de busqueda" onChange={keyChangeCriteria} onKeyUp={onKeyUpCriteria} value={criteria} />
                        <span className="input-group-text btn btn-info text-white" onClick={search}><FontAwesomeIcon icon={faSearch} /></span>
                        {
                            criteria.length?
                                <span className="input-group-text btn btn-secondary text-white" onClick={()=>setCriteria('')}><FontAwesomeIcon icon={faClose} /></span>
                            :null
                        }                        
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12 table-responsive'>
                    <DataTable 
                        columns={columns}
                        data={dataTableData}
                        title="Listado de materiales"
                        pagination
                        paginationComponentOptions={paginationOptions}
                        fixedHeader
                        fixedHeaderScrollHeight='600'
                        progressPending={loadingTable}
                    />
                </div>
            </div>        
        </div>
    )
}
export default MaterialDataTable;