import axios from "axios";
import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DateTimePicker from "react-datetime-picker";
import Swal from "sweetalert2";
const columns = [
    /*{
        name: 'CONFIRMAR/ABRIR',
        selector: row => row.chooseIcon,
        center: true
    },
    {
        name: 'CANCELAR',
        selector: row => row.deleteIcon,
        center: true
    },*/
    {
        name: '#',
        selector: row => row.id,
        sortable: true,
        center: true
    },
    {
        name: 'CLIENTE',
        selector: row => row.customer_name,
        sortable: true,
        center: true
    },
    {
        name: 'CORREO',
        selector: row => row.customer_email,
        center: true
    },
    {
        name: 'TELÉFONO',
        selector: row => row.bp_phone,
        center: true
    },
    {
        name: 'FECHA',
        selector: row => row.a_date,
        sortable: true,
        center: true
    },
    {
        name: 'ENCARGADO',
        selector: row => row.user_name,
        sortable: true,
        center: true
    },
    {
        name: 'ESTATUS',
        selector: row => row.status_des,
        right: true
    }    
];
const workingColumns = [
    {
        name: 'CERRAR/FACTURAR',
        selector: row => row.chooseIcon,
        center: true
    },
    {
        name: '#',
        selector: row => row.id,
        sortable: true,
        center: true
    },
    {
        name: 'CLIENTE',
        selector: row => row.customer_name,
        sortable: true,
        center: true
    },
    {
        name: 'CORREO',
        selector: row => row.customer_email,
        center: true
    },
    {
        name: 'TELÉFONO',
        selector: row => row.bp_phone,
        center: true
    },
    {
        name: 'FECHA',
        selector: row => row.a_date,
        sortable: true,
        center: true
    },
    {
        name: 'ENCARGADO',
        selector: row => row.user_name,
        sortable: true,
        center: true
    },
    {
        name: 'ESTATUS',
        selector: row => row.status_des,
        right: true
    }    
];
const nextColumns = [
    {
        name: '#',
        selector: row => row.id,
        sortable: true,
        center: true
    },
    {
        name: 'CLIENTE',
        selector: row => row.customer_name,
        sortable: true,
        center: true
    },
    {
        name: 'CORREO',
        selector: row => row.customer_email,
        center: true
    },
    {
        name: 'TELÉFONO',
        selector: row => row.bp_phone,
        center: true
    },
    {
        name: 'FECHA',
        selector: row => row.a_date,
        sortable: true,
        center: true
    },
    {
        name: 'ENCARGADO',
        selector: row => row.user_name,
        sortable: true,
        center: true
    },
    {
        name: 'ESTATUS',
        selector: row => row.status_des,
        right: true
    }    
];
const paginationOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos'
};
const UsrContent = (props) => {
    const [dateFilter,setDateFilter] = useState(new Date()),
        [loadingNextAppointments,setLoadingNextAppointments] = useState(false),
        [nextAppointmentList,setNextAppointmentList] = useState([]);
    const searchNextAppointments = async() => {
        setLoadingNextAppointments(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'appointments/next/by/usr',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                date: dateFilter
            }),
                data = await resource.data;
            setNextAppointmentList(data);
            setLoadingNextAppointments(false);
        } catch(error){
            setLoadingNextAppointments(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    return (
        <div className="container-fluid">
            <div className="row justify-content-center" style={{marginTop: '2.5rem'}}>
                {/* eslint-disable-next-line react/prop-types */}
                <div className="col-3" style={props.styling.headerInfoLabel}>
                    <h6 style={{marginTop: '15px'}}>Tus citas</h6>
                    {/* eslint-disable-next-line react/prop-types */}
                    <h1>{props.mData.myAppointments}</h1>
                </div>
                {/* eslint-disable-next-line react/prop-types */}
                <div className="col-3" style={props.styling.headerInfoLabel}>
                    <h6 style={{marginTop: '15px'}}>Confirmado</h6>
                    {/* eslint-disable-next-line react/prop-types */}
                    <h1>{props.mData.myConfirmAppointments}</h1>
                </div>
                {/* eslint-disable-next-line react/prop-types */}
                <div className="col-3" style={props.styling.headerInfoLabel}>
                    <h6 style={{marginTop: '15px'}}>Completado</h6>
                    {/* eslint-disable-next-line react/prop-types */}
                    <h1>{props.mData.myCompletedAppointments}</h1>
                </div>
                {/* eslint-disable-next-line react/prop-types */}
                <div className="col-3" style={props.styling.headerInfoLabel}>
                    <h6 style={{marginTop: '15px'}}>Cancelado</h6>
                    {/* eslint-disable-next-line react/prop-types */}
                    <h1>{props.mData.myCancelAppointments}</h1>
                </div>
            </div>
            <div className="row" style={{marginTop: '1.5rem'}}>
                {/* eslint-disable-next-line react/prop-types */}
                <div className="col-12 table-responsive" style={props.styling.headerInfoLabel}>
                    <DataTable 
                        columns={columns}
                        // eslint-disable-next-line react/prop-types
                        data={props.appointments}
                        title="Citas Pendientes"
                        pagination
                        paginationComponentOptions={paginationOptions}
                        fixedHeader
                        fixedHeaderScrollHeight='600'
                        // eslint-disable-next-line react/prop-types
                        progressPending={props.isLoading}
                    />
                </div>
            </div>
            <div className="row" style={{marginTop: '1.5rem'}}>
                {/* eslint-disable-next-line react/prop-types */}
                <div className="col-12 table-responsive" style={props.styling.headerInfoLabel}>
                    <DataTable 
                        columns={workingColumns}
                        // eslint-disable-next-line react/prop-types
                        data={props.workingAppointments}
                        title="En Asistencia"
                        pagination
                        paginationComponentOptions={paginationOptions}
                        fixedHeader
                        fixedHeaderScrollHeight='600'
                        // eslint-disable-next-line react/prop-types
                        progressPending={props.isLoading}
                    />
                </div>
            </div>
            <div className="row" style={{marginTop: '1.5rem'}}>
                <div className="col-6 form-group" style={{marginTop: '10px', marginBottom: '10px'}}>
                    <label className="mb-2" style={{fontWeight: 'bold'}}>Filtro de fecha</label><br />
                    <DateTimePicker
                        onChange={setDateFilter}
                        value={dateFilter}
                        format="dd-MM-y"
                        disableClock={true}
                    />
                    {
                        !loadingNextAppointments?
                            <Button variant="primary" size="sm" style={{marginLeft: '10px', marginTop: '-5px', marginBottom: '0px'}} onClick={searchNextAppointments}>Consultar proxima citas</Button>
                        :<Button variant="primary" size="sm" disabled>
                            <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            />
                            Loading...
                        </Button>
                    }
                </div>
                {/* eslint-disable-next-line react/prop-types */}
                <div className="col-12 table-responsive" style={props.styling.headerInfoLabel}>
                    <DataTable 
                        columns={nextColumns}
                        // eslint-disable-next-line react/prop-types
                        data={nextAppointmentList}
                        title="Próximas citas"
                        pagination
                        paginationComponentOptions={paginationOptions}
                        fixedHeader
                        fixedHeaderScrollHeight='600'
                        // eslint-disable-next-line react/prop-types
                        progressPending={props.isLoading}
                    />
                </div>
            </div>
        </div>
    )
}

export default UsrContent;