import React,{useEffect} from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';
const Auth = () => {
    const {path,session,token} = useParams();
    const navigate = useNavigate();
    useEffect(async()=>{
        if(token!='d386b0b2833ca48874909df75cd507e5'){
            //token not found
            // eslint-disable-next-line no-undef
            window.location.href = process.env.REACT_APP_PATH_APP;
        }
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'auto/auth/by/session/key',{session:session}),
                data = await resource.data;
            if(data.status){
                localStorage.setItem('OSHEN-LOC-STR-TK',session);
                navigate('/'+path);
            } else {
                // eslint-disable-next-line no-undef
                window.location.href = process.env.REACT_APP_PATH_APP;
            }
        } catch(error){
            // console.log('Internal Error', error);
            // eslint-disable-next-line no-undef
            window.location.href = process.env.REACT_APP_PATH_APP;
        }
    },[]);
    return (<><p>You will redirect to another page...</p></>);
}
export default Auth;