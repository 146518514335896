import React,{useState, useEffect, useRef} from "react";
import { Button, Image, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faCalendar, faUserPlus, faUserMinus, faTrash, faCheck, faFileAlt, faFlag, faLandmarkFlag, faTimes, faBuilding, faUser} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import axios from "axios";
import DateTimePicker from 'react-datetime-picker';
const styling = {
    font: {
        fontSize: '10px',
        fontWeight: 'bold',
        textTransform: 'initial'
    },
    fontBadge: {
        fontSize: '8px',
        fontWeight: 'bold',
        textTransform: 'initial'
    }
}
const assignData = {
    usr: 0,
    reason: '',
    token: ''
}
const TaskContent = (props) => {
    const [isAssigning,setIsAssigning] = useState(false),
        [aData,setAData] = useState(assignData),
        [aIsLoading,setAIsLoading] = useState(false),
        [rIsLoading,setRIsLoading] = useState(false),
        [assignInputs,setAssignInputs] = useState(false),
        [iconReportActivity,setIconReportActivity] = useState(false),
        [iconApproveOrRejected,setIconApproveOrRejected] = useState(false),
        [isLoading,setIsLoading] = useState(false),
        [reportComment,setReportComment] = useState(''),
        [reportHour,setReportHour] = useState(''),
        [startDate,setStartDate] = useState(new Date()),
        [endDate,setEndDate] = useState(new Date()),
        [approveInputs,setApproveInputs] = useState(false),
        [approveComment,setApproveComment] = useState(''),
        [taskProcessed,setTaskProcessed] = useState(false),
        [taskReturnToOpen,setTaskReturnToOpen] = useState(false),
        [attachFileInput,setAttachFileInput] = useState(null),
        [attachFileInputCompleted,setAttachFileInputCompleted] = useState(null),
        fileInputRef = useRef(),
        fileInputRefCompleted = useRef();
    useEffect(()=>{
        // eslint-disable-next-line react/prop-types
        if(parseInt(props.usr.user_id)==parseInt(props.taskInfo.user_id_assign)){
            //Who has assined is looking...
            setIconReportActivity(true);
        }
        // eslint-disable-next-line react/prop-types
        if(parseInt(props.usr.user_id)==parseInt(props.taskInfo.user_id)){
            //Who has assined is looking...
            setIconApproveOrRejected(true);
            //Who has requested can do feedback
            setIconReportActivity(true);
        } else {
            setIconApproveOrRejected(false);
        }
        /** when the interface show up, don't show approve par */
        setIsAssigning(false);
        setAssignInputs(false);
        setApproveInputs(false);
    // eslint-disable-next-line react/prop-types
    },[props.taskInfo]);
    const clearAssignInputs = () => {
        setStartDate(new Date());
        setEndDate(new Date());
        setAData({
            ...assignData,
            usr: 0,
            reason: '',
            token: ''
        });
    }
    const assignUsr = (e) => {
        const k = e.currentTarget.id,
            v = e.currentTarget.value;
        setAData({
            ...aData,
            [k]:v
        })
    }
    const assignInfoToUsr = async () => {
        setAIsLoading(true);
        try{
            const sD = new Date(startDate),
            sDate = sD.getFullYear() + '-' + (sD.getMonth()+1) + '-' + sD.getDate(),
            eD = new Date(endDate),
            eDate = eD.getFullYear() + '-' + (eD.getMonth()+1) + '-' + eD.getDate();
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'assign/tasks',{
                aData: aData, 
                sDate: sDate, 
                eDate: eDate, 
                // eslint-disable-next-line react/prop-types
                viewer: props.viewer,
                // eslint-disable-next-line react/prop-types
                id: props.taskInfo.id,
                token: localStorage.getItem('OSHEN-LOC-STR-TK')
            }),
                data = await resource.data;
            setAIsLoading(false);
            // eslint-disable-next-line no-prototype-builtins
            if(data!=undefined&&data.log==false){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                return false;
            }
            if(data.response==false){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                return false;
            }
            setIsAssigning(false);
            // eslint-disable-next-line react/prop-types
            props.setTaskInfo(data.task);
            // eslint-disable-next-line react/prop-types
            props.setInfo({
                // eslint-disable-next-line react/prop-types
                ...props.info,
                tasks: data.tasks
            });
            // eslint-disable-next-line react/prop-types
            props.setTaskOpenned(data.tasksOP);
            // eslint-disable-next-line react/prop-types
            props.setLastTasks(data.lastTasks);
            clearAssignInputs();
        } catch(error){
            setAIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error interno, favor contactar al administrador'
            });
        }
    }
    const revokeTask = () => {
        Swal.fire({
            icon: 'warning',
            input: 'text',
            title: '¿Esta seguro de revocar esta tarea?',
            text: 'No podrá registrar ninguna actividad nueva para esta tarea',
            showCancelButton: true,
            confirmButtonText: 'Proceder'
        }).then((result)=>{
            if(result.isConfirmed){
                if(result.value){
                    revoke(result.value);
                } else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Advertencia',
                        text: 'Debe ingresar la razon por la cual esta revocando esta tarea'
                    });
                }        
            }
        });
    }
    const revoke = async (reason) => {
        setRIsLoading(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'revoke/tasks',{
                // eslint-disable-next-line react/prop-types
                id: props.taskInfo.id,
                reason: reason,
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                // eslint-disable-next-line react/prop-types
                viewer: props.viewer
            }),
            data = await resource.data;
            setRIsLoading(false);
            // eslint-disable-next-line no-prototype-builtins
            if(data!=undefined&&data.log==false){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                return false;
            }
            if(data.response==false){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                return false;
            }
            // eslint-disable-next-line react/prop-types
            props.setTaskInfo(data.task);
            // eslint-disable-next-line react/prop-types
            props.setInfo({
                // eslint-disable-next-line react/prop-types
                ...props.info,
                tasks: data.tasks
            });
            // eslint-disable-next-line react/prop-types
            props.setLastTasks(data.lastTasks);
        } catch(error){
            setRIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error interno, favor contactar al administrador'
            });
        }
    }
    const reportActivity = async () => {
        setIsLoading(true);
        try{
            const formData = new FormData();
            if(attachFileInput!=undefined){
                formData.append('attach',attachFileInput,attachFileInput.name);
            }
            // eslint-disable-next-line react/prop-types
            formData.append("id",props.taskInfo.id);
            formData.append("reason",reportComment);
            formData.append("hour",reportHour);
            formData.append("processed",taskProcessed);
            formData.append("open",taskReturnToOpen);
            formData.append("token",localStorage.getItem('OSHEN-LOC-STR-TK'));
            // eslint-disable-next-line react/prop-types
            formData.append("viewer",props.viewer);            
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'report/activity',formData/*{
                // eslint-disable-next-line react/prop-types
                id: props.taskInfo.id,
                reason: reportComment,
                hour: reportHour,
                processed: taskProcessed,
                open: taskReturnToOpen,
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                // eslint-disable-next-line react/prop-types
                viewer: props.viewer
            }*/),
                data = await resource.data;
                setIsLoading(false);
            // eslint-disable-next-line no-prototype-builtins
            if(data!=undefined&&data.log==false){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                return false;
            }
            if(data.response==false){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                return false;
            }
            setAssignInputs(false);
            setReportComment('');
            setReportHour('');
            setTaskProcessed(false);
            setTaskReturnToOpen(false);
            // eslint-disable-next-line react/prop-types
            props.setTaskInfo(data.task);
            // eslint-disable-next-line react/prop-types
            props.setInfo({
                // eslint-disable-next-line react/prop-types
                ...props.info,
                tasks: data.tasks
            });
            // eslint-disable-next-line react/prop-types
            props.setTaskFA(data.tasksFA);
            // eslint-disable-next-line react/prop-types
            props.setTaskOpenned(data.tasksOP);
            // eslint-disable-next-line react/prop-types
            props.setLastTasks(data.lastTasks);
        } catch(error){
            setAIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error interno, favor contactar al administrador'
            });
        }
    }
    const CompleteActivity = async () => {
        setIsLoading(true);
        try{
            const formData = new FormData();
            if(attachFileInputCompleted!=undefined){
                formData.append('attach',attachFileInputCompleted,attachFileInputCompleted.name);
            }
            // eslint-disable-next-line react/prop-types
            formData.append("id",props.taskInfo.id);
            formData.append("reason",approveComment);
            formData.append("token",localStorage.getItem('OSHEN-LOC-STR-TK'));
            // eslint-disable-next-line react/prop-types
            formData.append("viewer",props.viewer);
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'complete/activity',formData/*{
                // eslint-disable-next-line react/prop-types
                id: props.taskInfo.id,
                reason: approveComment,
                // eslint-disable-next-line react/prop-types
                viewer: props.viewer,
                token: localStorage.getItem('OSHEN-LOC-STR-TK')
            }*/),
                data = await resource.data;
                setIsLoading(false);
            // eslint-disable-next-line no-prototype-builtins
            if(data!=undefined&&data.log==false){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                return false;
            }
            if(data.response==false){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                return false;
            }
            setApproveInputs(false);
            setApproveComment('');
            // eslint-disable-next-line react/prop-types
            props.setTaskInfo(data.task);            
            // eslint-disable-next-line react/prop-types
            props.setTaskFA(data.tasks);
            // eslint-disable-next-line react/prop-types
            props.setInfo({
                // eslint-disable-next-line react/prop-types
                ...props.info,
                tasks: data.taskList
            });
            // eslint-disable-next-line react/prop-types
            props.setLastTasks(data.lastTasks);
        } catch(error){
            setAIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error interno, favor contactar al administrador'
            });
        }
    }
    const markAsProcessed = (input) => {
        setTaskProcessed(input.target.checked);
    }
    const onChangeAttachFile = (evn) => {
        const file = evn.target.files[0],
            reader = new FileReader(),
            // eslint-disable-next-line no-unused-vars
            url = reader.readAsDataURL(file);
        setAttachFileInput(file);
        reader.onloadend = () => {
            //setTmpImage(reader.result);
        }
    }
    const onChangeAttachFileCompleted = (evn) => {
        const file = evn.target.files[0],
            reader = new FileReader(),
            // eslint-disable-next-line no-unused-vars
            url = reader.readAsDataURL(file);
        setAttachFileInputCompleted(file);
        reader.onloadend = () => {
            //setTmpImage(reader.result);
        }
    }
    return (
        // eslint-disable-next-line react/prop-types
        props.loadingTaskInfo?
            <div className="d-flex justify-content-center">
                <Spinner animation="border" variant="primary" />
            </div>
        // eslint-disable-next-line react/prop-types
        : props.taskInfo.subject!==undefined?
        <>
            <table className="table task-table-2" style={{marginBottom: 0}}>
                <thead>
                    <tr>
                        <th style={{width: '10%'}}>
                            <Image 
                                style={{width: '32px'}}
                                fluid={true}
                                roundedCircle={true}
                                thumbnail={true}
                                // eslint-disable-next-line no-undef, react/prop-types
                                src={process.env.REACT_APP_PATH_APP+props.taskInfo.path_file}
                            />
                        </th>                        
                        <th style={{width: '70%'}} className="task-header">
                            {/* eslint-disable-next-line react/prop-types */}
                            <span style={styling.fontBadge} className={`badge text-bg-${props.taskInfo.status_i}`}>{props.taskInfo.status_des}</span><br />
                            {/* eslint-disable-next-line react/prop-types */}
                            Solicitud #{props.taskInfo.id} por: {props.taskInfo.personal_name}
                        </th>
                        <th style={{width: '20%'}} className="task-header">
                            {
                                // eslint-disable-next-line react/prop-types
                                props.allowApprove==true&&props.taskInfo.status_i=='warning'?
                                <>
                                    {
                                        isAssigning ?
                                            <FontAwesomeIcon icon={faUserMinus} title="No asignar" style={{cursor: 'pointer',color: '#dc3545'}} onClick={()=>setIsAssigning(false)} className="task-action-btn" />
                                        : <FontAwesomeIcon icon={faUserPlus} title="Asignar tarea a usuario" style={{cursor: 'pointer',color: '#198754'}} onClick={()=>{clearAssignInputs();setIsAssigning(true);}} className="task-action-btn" />
                                    }
                                    &nbsp;&nbsp;
                                    {
                                        rIsLoading?
                                        <Spinner animation="border" variant="primary" />
                                        :<FontAwesomeIcon icon={faTrash} title="Revocar tarea" style={{cursor: 'pointer',color: '#dc3545'}} onClick={revokeTask} className="task-action-btn" />
                                    }                                    
                                </>
                                :null
                            }
                            {
                                assignInputs?<FontAwesomeIcon icon={faLandmarkFlag} title="Ya no quiero reportar actividad" style={{cursor: 'pointer',color: '#f00'}} onClick={()=>setAssignInputs(false)} className="task-action-btn" />
                                // eslint-disable-next-line react/prop-types
                                :iconReportActivity&&(props.taskInfo.status_i=='primary'||(props.taskInfo.status_i=='info'&&parseInt(props.usr.user_id)==parseInt(props.taskInfo.user_id)))?
                                <FontAwesomeIcon icon={faFlag} title="Reportar Actividad" style={{cursor: 'pointer',color: '#0d6efd',marginLeft: '1rem'}} onClick={()=>setAssignInputs(true)} className="task-action-btn" />
                                :null
                            }
                            {
                                approveInputs?
                                <FontAwesomeIcon icon={faTimes} title="Cancelar operación" style={{cursor: 'pointer',color: '#f00',marginLeft: '1rem'}} onClick={()=>{setApproveInputs(false);}} className="task-action-btn" />
                                :
                                // eslint-disable-next-line react/prop-types
                                iconApproveOrRejected&&props.taskInfo.status_i=='info'?
                                    <>                                    
                                    <FontAwesomeIcon icon={faCheck} title="La tarea fue completada" style={{cursor: 'pointer',color: '#198754',marginLeft: '1rem'}} onClick={()=>setApproveInputs(true)} className="task-action-btn" />
                                    </>
                                :null
                            }
                        </th>
                    </tr>
                </thead>
            </table>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <table className="table">
                           <tbody>
                           {
                            // eslint-disable-next-line react/prop-types
                            props.inputsPermission&&props.taskInfo.status_i != 'success'?
                                <tr>
                                    <td colSpan={2}>
                                        <Button variant="primary" size="sm" style={{marginRight: '1rem'}} onClick={()=>setApproveInputs(true)}>
                                            <FontAwesomeIcon icon={faCheck} /> Completar
                                        </Button>
                                        <Button variant="danger" size="sm" onClick={revokeTask}>
                                            <FontAwesomeIcon icon={faTimes} /> Revocar
                                        </Button>
                                    </td>
                                </tr>
                            : null
                           }                            
                            <tr>                                
                                <td colSpan={2} className="task-content-header">
                                    {/* eslint-disable-next-line react/prop-types */}
                                    <label><FontAwesomeIcon icon={faClock} />&nbsp;{props.taskInfo.subject}</label><br />
                                    {/* eslint-disable-next-line react/prop-types */}
                                    <span className="text-muted" style={{fontSize: '9px',marginRight: '1rem'}}><FontAwesomeIcon icon={faBuilding} />&nbsp;{props.taskInfo.dep}</span>
                                    {/* eslint-disable-next-line react/prop-types */}
                                    {/* <span className="text-muted" style={{fontSize: '9px',marginRight: '1rem'}}><FontAwesomeIcon icon={faListOl} />&nbsp;{props.taskInfo.cond}</span> */}
                                    {/* eslint-disable-next-line react/prop-types */}
                                    <span className="text-muted" style={{fontSize: '9px',marginRight: '1rem'}}><FontAwesomeIcon icon={faCalendar} />&nbsp;{props.taskInfo.createdAtFormatted}</span>
                                    {/* eslint-disable-next-line react/prop-types */}
                                    <span className="text-muted" style={{fontSize: '9px'}}><FontAwesomeIcon icon={faUser} />&nbsp;{props.taskInfo.personal_name}</span>
                                </td>
                            </tr>
                            <tr>                                
                                <td colSpan={2} className="task-content-detail">
                                    <h6 style={{textDecoration: 'underline'}}>Detalle Tarea</h6>
                                    {/* eslint-disable-next-line react/prop-types */}
                                    <h3>{props.taskInfo.detail}</h3>
                                </td>
                            </tr>
                            <tr>                                
                                <td colSpan={2} className="task-content-detail">
                                    <h6 style={{textDecoration: 'underline'}}>Seguimiento</h6>
                                    {
                                        // eslint-disable-next-line react/prop-types
                                        props.taskInfo.activities.map((inpt,idx)=>{
                                            return <div key={idx}>
                                                        <label><span><strong>Reporte de usuario</strong></span> {inpt.personal_name}{inpt.qtyHours>0?<span>{inpt.qtyHours}hr</span>:null}, <span className="text-muted">{inpt.createdAt}</span></label>
                                                        <h6 style={{fontWeight: 'bold'}}>{inpt.comment}</h6>                                                        
                                                        {
                                                            inpt.file?
                                                                <><br /><span><a href={inpt.file} target="_blank" rel="noreferrer">Descargar archivo adjunto de actividad</a></span></>
                                                            :null
                                                        }
                                                    </div>
                                        })
                                    }
                                    {/* eslint-disable-next-line react/prop-types */}
                                    {props.taskInfo.personal_name_assign?                                         
                                        // eslint-disable-next-line react/prop-types
                                        <><span><strong>Tarea asignada a</strong> {props.taskInfo.personal_name_assign_by} <strong>por</strong> {props.taskInfo.personal_name_asignador}, {props.taskInfo.assignedToFormatted}</span></>
                                    :null
                                    }
                                    {/* eslint-disable-next-line react/prop-types */}
                                    <><br /><span><strong>Tarea creada por</strong> {props.taskInfo.personal_name}, {props.taskInfo.createdAtFormatted}</span></>
                                </td>
                            </tr>
                            {
                                // eslint-disable-next-line react/prop-types
                                props.taskInfo.files.length?
                                    // eslint-disable-next-line react/prop-types
                                    props.taskInfo.files.map((element,idx)=>{
                                        return (
                                            <tr key={idx}>
                                                <td colSpan={2}>
                                                    {/* eslint-disable-next-line no-undef */}
                                                    <a href={process.env.REACT_APP_TASK_PATH_FILE+'files/taskFiles/'+element.path_file} target="_blank" rel="noreferrer" style={styling.font}><FontAwesomeIcon icon={faFileAlt} /> Descargar archivo adjunto #{element.file_id}</a>
                                                </td>
                                            </tr>
                                        )
                                    })
                                :null
                            }
                            {
                                isAssigning?
                                <>
                                <tr>
                                    <td>
                                        <label style={{fontSize: '12px',fontWeight: 'bold',textTransform: 'initial'}}>Fecha de inicio</label><br />
                                        <DateTimePicker
                                            onChange={setStartDate}
                                            value={startDate}
                                            format="dd-MM-y"
                                            disableClock={true}
                                        />
                                    </td>
                                    <td>
                                        <label style={{fontSize: '12px',fontWeight: 'bold',textTransform: 'initial'}}>Fecha de fin</label><br />
                                        <DateTimePicker
                                            onChange={setEndDate}
                                            value={endDate}
                                            format="dd-MM-y"
                                            disableClock={true}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                    <select className="form-select" defaultValue={aData.usr} id="usr" onChange={assignUsr}>
                                        <option value={0}>Asignar Tarea</option>
                                        {
                                            // eslint-disable-next-line react/prop-types
                                            props.usrList.map((elm,idx)=>{
                                                return <option key={idx} value={elm.user_id}>{elm.personal_name}</option>
                                            })
                                        }
                                    </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <textarea className="form-control" id="reason" rows="3" onChange={assignUsr} value={aData.reason} placeholder="Debes ingresar una razón"></textarea>
                                    </td>
                                    <td>
                                        {
                                            !aIsLoading?
                                                <button type="button" onClick={assignInfoToUsr} className="btn btn-success btn-sm" style={{width: '100%',height: '5.2rem'}}><FontAwesomeIcon icon={faCheck} /> Asignar</button>
                                            : <Button variant="success" style={{width: '100%',height: '5.2rem'}} disabled>
                                                <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                />
                                                Loading...
                                            </Button>
                                        }                                        
                                    </td>
                                </tr>
                                </>
                                :null
                            }
                            {
                                assignInputs?
                                <>
                                <tr>
                                    <td>
                                        <input 
                                            type="number" 
                                            className="form-control" 
                                            id="hour" 
                                            onChange={(input)=>setReportHour(input.currentTarget.value)} 
                                            value={reportHour} 
                                            placeholder="Horas Trabajadas"
                                            style={{width: '50%'}}
                                        />
                                    </td>
                                    <td>
                                        {
                                            !iconApproveOrRejected?
                                            <>
                                                <input 
                                                    style={{width: '12px',height: '12px',marginTop: '8px',marginRight: '5px'}}
                                                    className="form-check-input" 
                                                    type="checkbox" 
                                                    value="" 
                                                    onChange={markAsProcessed} />
                                                <label className="form-check-label" style={{fontSize: '12px'}}>
                                                    Tarea Completada
                                                </label>
                                            </>
                                            :
                                            // eslint-disable-next-line react/prop-types
                                            props.taskInfo.status_i == 'info'?
                                            <>
                                                <input 
                                                    style={{width: '12px',height: '12px',marginTop: '8px',marginRight: '5px'}}
                                                    className="form-check-input" 
                                                    type="checkbox" 
                                                    value="" 
                                                    onChange={(input)=>setTaskReturnToOpen(input.target.checked)} />
                                                <label className="form-check-label" style={{fontSize: '12px'}}>
                                                    Retornar Tarea
                                                </label>
                                            </>
                                            :null
                                        }                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <input className="form-control" type="file" id="attachFileToReport" onChange={onChangeAttachFile} ref={fileInputRef} />
                                        <span className="text-muted" style={{fontSize: "12px", textTransform: "initial", textAlign: "left"}}>Adjuntar un archivo(.pdf, .xls, .png, .jpg, .jpeg)</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <textarea className="form-control" id="comment" rows="3" onChange={(input)=>setReportComment(input.currentTarget.value)} value={reportComment} placeholder="Debes ingresar una razón"></textarea>
                                    </td>
                                    <td>
                                        {
                                            !isLoading?
                                                <button type="button" onClick={reportActivity} className="btn btn-primary btn-sm" style={{width: '100%',height: '5.2rem'}}>
                                                    <FontAwesomeIcon icon={faFlag} /> Reportar
                                                </button>
                                            : <Button variant="primary" style={{width: '100%',height: '5.2rem'}} disabled>
                                                <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                />
                                                Loading...
                                            </Button>
                                        }                                        
                                    </td>
                                </tr>
                                </>
                                :null
                            }
                            {
                                approveInputs?
                                <>
                                <tr>
                                    <td colSpan={2}>
                                        <input className="form-control" type="file" id="attachCompletedFileToReport" onChange={onChangeAttachFileCompleted} ref={fileInputRefCompleted} />
                                        <span className="text-muted" style={{fontSize: "12px", textTransform: "initial", textAlign: "left"}}>Adjuntar un archivo(.pdf, .xls, .png, .jpg, .jpeg)</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <textarea className="form-control" id="approveComment" rows="3" onChange={(input)=>setApproveComment(input.currentTarget.value)} value={approveComment} placeholder="Debes ingresar una razón"></textarea>
                                    </td>
                                    <td>
                                        {
                                            !isLoading?
                                                <button type="button" onClick={CompleteActivity} className="btn btn-success btn-sm" style={{width: '100%',height: '5.2rem'}}>
                                                    <FontAwesomeIcon icon={faCheck} /> Completar
                                                </button>
                                            : <Button variant="success" style={{width: '100%',height: '5.2rem'}} disabled>
                                                <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                />
                                                Loading...
                                            </Button>
                                        }                                        
                                    </td>
                                </tr>
                                </>
                                :null
                            }
                           </tbody> 
                        </table>
                    </div>
                </div>
            </div>
        </>
        :null
    )
}

export default TaskContent;