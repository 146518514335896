import { faFileCirclePlus, faPlusCircle, faRefresh, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Alert, Badge } from "react-bootstrap";
import React, {useState,useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import TimePicker from "react-time-picker";
import Swal from "sweetalert2";
const defaultData = {
    customer: 0,
    ADate: '',
    attendant: 0,
    services: []
};
const AppointmentModel = (props) => {
    const hourDefault = new Date(),
        dHour = hourDefault.getHours().toString().padStart(2,0),
        mHour = hourDefault.getMinutes().toString().padStart(2,0),
        completeHourDefault = dHour+':'+mHour;
    const [data,setData] = useState(defaultData),
        [serviceSelected,setServiceSelected] = useState(0),
        [manageDate,setManageDate] = useState(new Date()),
        [hourDate,setHourDate] = useState(completeHourDefault),
        [userSpaces,setUserSpaces] = useState([]),
        [userForTimer,setUserForTimer] = useState();
    useEffect(()=>{
        // eslint-disable-next-line react/prop-types
        if(props.show===false){
            setData(defaultData);
            setServiceSelected(0);
            setManageDate(new Date());
            setUserSpaces([]);
        }
    // eslint-disable-next-line react/prop-types
    },[props.show]);
    const setter = (input) => {
        const key = input.currentTarget.id,
            value = input.currentTarget.value;
        setData({
            ...data,
            [key]: value
        })
    }
    const addServiceToList = () => {
        if(serviceSelected<1){
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: "Debe seleccionar al menos un servicio/producto"
            });
            return false;
        }
        let product = {},
            services = [...data.services];        
        // eslint-disable-next-line react/prop-types
        props.mData.products.map((element)=>{
            if(serviceSelected==element.id){
                product = {
                    coding: element.coding,
                    product: element.product,
                    price: element.price
                }
                return;
            }
        });
        services.push({
            product:serviceSelected,
            code:product.coding,
            description:product.product,
            pricing:product.price
        });
        setData({
            ...data,
            services: services
        })
    }
    const removeFromList = (input) => {
        let services = [...data.services],
            product = input.currentTarget.id,
            idx = null;
        for(let i=0;i<services.length;i++){
            if(parseInt(services[i].product)==parseInt(product)){
                idx = i;
                break;
            }
        }
        services.splice(idx,1);
        setData({
            ...data,
            services: services
        });
    }
    const refreshCustomer = async() => {
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.get(process.env.REACT_APP_PATH_API + 'appointment/customers'),
            customers = resource.data;
            // eslint-disable-next-line react/prop-types
            props.setMData({
                // eslint-disable-next-line react/prop-types
                ...props.mData,
                customers: customers
            })
        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    const refreshProducts = async() => {
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.get(process.env.REACT_APP_PATH_API + 'appointment/products'),
            products = resource.data;
            // eslint-disable-next-line react/prop-types
            props.setMData({
                // eslint-disable-next-line react/prop-types
                ...props.mData,
                products: products
            })
        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    const checkTimesAvailable = async() => {
        try{
            const date = new Date(manageDate),
            mDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'appointment/check/times',{
                date: mDate,
                token: localStorage.getItem('OSHEN-LOC-STR-TK')
            }),
            users = resource.data;
            setUserSpaces(users);
        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    const createAppointment = async () => {
        const date = new Date(manageDate),
            mDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        
        let hourSplit = hourDate.toString().split(":"),
            ADate = mDate + ' ' + hourSplit[0] + ':' + hourSplit[1];
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'appointment/crt',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                data: {
                    customer: parseInt(data.customer),
                    ADate: ADate,
                    attendant: parseInt(userForTimer),
                    services: data.services
                }
            }),
            response = resource.data;
            if(!response.status){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: response.message
                });
                return;
            }
            Swal.fire({
                icon: 'success',
                title: 'Operación Exitosa',
                text: response.message
            }).then(()=>{
                // // eslint-disable-next-line react/prop-types
                // props.setResetAppointmentConfirmation(response.appointmentConfirmation);
                // // eslint-disable-next-line react/prop-types
                // props.close(false);
                // setData(defaultData);
                // setServiceSelected(0);
                // setManageDate(new Date());
                // setUserSpaces([]);
                location.reload();
            });
        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    return (
        // eslint-disable-next-line react/prop-types
        <Modal show={props.show} backdrop="static" size="lg" onHide={()=>{props.close(false)}}>
            <Modal.Header className='custom-modal-header-color' closeButton>
                <Modal.Title className=''>Agendar cita</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 form-group mb-3">
                            <label className="mb-2">Cliente</label>
                            <div className="input-group">
                                <select id="customer" className="form-select" defaultValue={data.customer} onChange={setter}>
                                    <option value={0}>Selecciona el cliente para esta cita</option>
                                    {
                                        // eslint-disable-next-line react/prop-types
                                        props.mData.customers.map((element,idx)=>{
                                            return <option value={element.id} key={idx}>{element.customer_name}</option>
                                        })
                                    }
                                </select>
                                {/* eslint-disable-next-line no-undef */}
                                <span className="input-group-text btn btn-success" onClick={()=>window.open(process.env.REACT_APP_PATH_APP+'customer?app=13','_blank')}><FontAwesomeIcon icon={faFileCirclePlus} title="Crear nuevo cliente" /></span>
                                <span className="input-group-text btn btn-info" onClick={refreshCustomer}><FontAwesomeIcon icon={faRefresh} title="Actualizar listado de cliente" /></span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 form-group mb-3">
                            <label className="mb-2">Servicio/Productos</label>
                            <div className="input-group">
                                <select className="form-select" defaultValue={serviceSelected} onChange={(input)=>setServiceSelected(input.currentTarget.value)}>
                                    <option value={0}>Agrega los servicios necesarios a la lista</option>
                                    {
                                        // eslint-disable-next-line react/prop-types
                                        props.mData.products.map((element,idx)=>{
                                            return <option value={element.id} key={idx}>{element.coding+' | '+element.product+' | $'+parseFloat(element.price).toFixed(2)}</option>
                                        })
                                    }
                                </select>
                                {/* eslint-disable-next-line no-undef */}
                                <span className="input-group-text btn btn-primary" onClick={addServiceToList}><FontAwesomeIcon icon={faPlusCircle} title="Agregar servicio/producto a la lista" /></span>
                                {/* eslint-disable-next-line no-undef */}
                                <span className="input-group-text btn btn-success" onClick={()=>window.open(process.env.REACT_APP_PATH_APP+'product?app=10','_blank')}><FontAwesomeIcon icon={faFileCirclePlus} title="Crear nuevo Servicio/Producto" /></span>
                                <span className="input-group-text btn btn-info" onClick={refreshProducts}><FontAwesomeIcon icon={faRefresh} title="Actualizar listado de Servicios/Productos" /></span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Producto</th>
                                        <th>Precio</th>
                                        <th>Remover</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.services.map((element,idx)=>{
                                            return (
                                                <tr key={'prd-'+idx}>
                                                    <td>{element.code}</td>
                                                    <td>{element.description}</td>
                                                    <td>${parseFloat(element.pricing).toFixed(2)}</td>
                                                    <td>
                                                        <Button variant="danger" size="sm" id={element.product} title="Remover de la lista" onClick={removeFromList}>
                                                            <FontAwesomeIcon icon={faTimes} />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3 form-group">
                            <label className="mb-2">Fecha</label><br />
                            <DateTimePicker
                                onChange={setManageDate}
                                value={manageDate}
                                format="dd-MM-y"
                                disableClock={true}
                            />
                        </div>
                        <div className="col-4">
                            <label className="mb-2 text-white">-</label><br />
                            <label className="mb-2"><Button variant="primary" size="sm" onClick={checkTimesAvailable}>Ver disponibilidad</Button></label>
                        </div>
                    </div>
                    {
                        userSpaces.length?
                            <div className="row">
                                <div className="col-12">
                                    <table className="table appointment-table">
                                        <tbody>
                                            {
                                                userSpaces.map((element,idx)=>{
                                                    const Info = element.taken.length?
                                                        element.taken.map((e,i)=>{
                                                            return <Badge key={i} bg="danger" style={{margin: '5px'}}>{e.hour_a_date}</Badge>
                                                        })
                                                    :
                                                        <Alert key="success" variant="success" style={{padding: 0}}>Esta disponible</Alert>
                                                    return (
                                                        <tr key={idx} onClick={(()=>setUserForTimer(element.id))}>
                                                            <th style={{width: '30%'}}>{element.user_name}</th>
                                                            <td style={{width: '70%'}}>
                                                                {Info}
                                                            </td>
                                                            {
                                                                parseInt(userForTimer)>0&&parseInt(userForTimer)==parseInt(element.id)?
                                                                    <td>
                                                                        <TimePicker
                                                                            onChange={(value)=>{console.log("hour",value);setHourDate(value)}}
                                                                            value={hourDate}
                                                                            format="h:mm a"
                                                                            clearIcon={null}
                                                                        />
                                                                    </td>
                                                                :null
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        :null
                    }                                        
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="row">
                    <div className="col-12" style={{marginTop: '2rem'}}>
                        {/* eslint-disable-next-line react/prop-types */}
                        <Button variant="danger" onClick={()=>{props.close(false)}}>Cerrar</Button>
                        <Button variant="primary" onClick={createAppointment} style={{marginLeft: '1rem'}}>Crear Cita</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default AppointmentModel;