import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import PropTypes from  'prop-types';
import axios from 'axios';
const SearchFinishedProductInput = (props) => {
    const {fn, loadingFn} = props,
        [criteria,setCriteria] = useState(''),
        [firstTime,setFirstTime] = useState(true);
    const keyChangeCriteria = (input) => {
        const value = input.target.value;
        setCriteria(value);
    }
    const onKeyUpCriteria = (input) => {
        if(input.key=='Enter'){
            search();
        }
    }
    const productConsult = async () => {
        loadingFn(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/datatable/products/finished',{criteria: criteria,token: localStorage.getItem('OSHEN-LOC-STR-TK')}),
                data = resource.data;            
            // for(let i=0;i<data.length;i++){
            //     data[i].chooseIcon = <FontAwesomeIcon id={parseInt(data[i].product_id)} icon={faArrowRightLong} onClick={selectItem} style={{fontSize: '14px',fontWeight: 'bold',cursor: 'pointer'}} />
            // }
            fn(data.dataTable);
            loadingFn(false);

            if(firstTime)
                setFirstTime(false);

        } catch(error){
            loadingFn(false);
            console.error('Internal Error',error);
        }
    }
    const search = async () => {
        if(criteria)
        {
            productConsult();
            // loadingFn(true);
            // try{
            //     // eslint-disable-next-line no-undef
            //     const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/datatable/products/finished',{criteria: criteria,token: localStorage.getItem('OSHEN-LOC-STR-TK')}),
            //         data = resource.data;
            //     fn(data.dataTable);
            //     loadingFn(false);
            // } catch(error){
            //     loadingFn(false);
            //     console.error('Internal Error',error);
            // }
        }
    }
    const resetData = () => {
        setCriteria('');
    }
    useEffect(()=>{
        if(criteria.length<=0&&!firstTime){
            productConsult();            
        }
    },[criteria]);
    return (
        <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Criterio de busqueda" onChange={keyChangeCriteria} onKeyUp={onKeyUpCriteria} value={criteria} />
            <span className="input-group-text btn btn-info text-white" onClick={search}><FontAwesomeIcon icon={faSearch} /></span>
            {
                criteria.length?
                    <span className="input-group-text btn btn-secondary text-white" onClick={resetData}><FontAwesomeIcon icon={faClose} /></span>
                :null
            }                        
        </div>
    );
}
SearchFinishedProductInput.propTypes = {
    fn: PropTypes.func,
    loadingFn: PropTypes.func
}
export default SearchFinishedProductInput;