import { faRefresh, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState, useEffect } from "react"
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";

const OrderDispatch = () => {
    const [isLoading,setIsLoading] = useState(false),
        [isPreparing,setIsPreparing] = useState(false),
        [accounts,setAccounts] = useState([]);
    const getAccounts = async () => {
        setIsLoading(true);
        try{
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/orders/dispatch',
            {
                token: localStorage.getItem('OSHEN-LOC-STR-TK')
            }),
            data = await resource.data;
            if(data.sessionOut){
                // eslint-disable-next-line no-undef
                location.href=process.env.REACT_APP_PATH_APP;
                return;
            }
            setIsLoading(false);
            setAccounts(data);
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon:'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    const orderPrepared = async (element) => {
        const quotationId = parseInt(element.currentTarget.getAttribute("id"));
        if(quotationId>0)
        {
            setIsPreparing(true);
            try{
                const resource = await axios.post(process.env.REACT_APP_PATH_API + 'set/order/ready',
                {
                    token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                    quotationId: quotationId
                }),
                data = await resource.data;
                if(data.sessionOut){
                    // eslint-disable-next-line no-undef
                    location.href=process.env.REACT_APP_PATH_APP;
                    return;
                }
                setIsPreparing(false);
                setAccounts(data.orders);
            } catch(error){
                setIsPreparing(false);
                Swal.fire({
                    icon:'error',
                    title: 'Error interno',
                    text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
                });
            }
        }
    }
    useEffect(()=>{
        getAccounts(); 
        setInterval( () => { getAccounts() }, 75000);
    },[]);
    return (
        <Container fluid>
            {
                isLoading?
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <Spinner animation="border" role="loading" />
                        </Col>
                    </Row>
                :
                <>
                <Row>
                    <Col style={{fontSize: '32px',textAlign: 'center',textTransform: 'uppercase'}}>Pedidos para despacho</Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <Button variant="secondary" style={{marginRight: '15px'}} onClick={()=> { 
                            // eslint-disable-next-line no-undef
                            location.href=process.env.REACT_APP_PATH_APP; 
                        }}><FontAwesomeIcon icon={faRightFromBracket} /> Regresar aplicación</Button>
                        <Button variant="primary" onClick={()=> { getAccounts() }}><FontAwesomeIcon icon={faRefresh} /> Refrescar Pedidos</Button>
                    </Col>
                </Row>
                <Row>
                {
                    accounts.map((element,idx)=>{
                        return (                            
                            <Col key={idx} xs={4} lg={4}>
                                <Card bg="light" style={{ width: '100%',marginTop: '15px' }}>                        
                                    <Card.Body>                                        
                                        <Card.Title>{element.customer_name}</Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted">                                            
                                            Cuenta#{element.id}
                                            {
                                                (element.mesa_id!=null)?
                                                <>
                                                    <br /> <span className="badge text-bg-primary">{element.mesa_des}</span>
                                                </>
                                                :null
                                            }
                                        </Card.Subtitle>
                                        {
                                            element.qDetails.map((detailElement,detailIdx)=>{
                                                return (
                                                    <Card.Text key={detailIdx}><strong>{detailIdx+1}</strong> {detailElement.product_des} | <strong style={{fontSize: '18px'}}>{detailElement.qty + ' ' + detailElement.unit_des.toString().toUpperCase()}</strong></Card.Text>
                                                )
                                            })
                                        }    
                                        {
                                            isPreparing?
                                                <Button variant="primary"><Spinner animation="border" role="loading" /> Cargando...</Button>
                                            :<Button id={element.id} variant="primary" onClick={orderPrepared}>Orden Lista</Button>
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })
                }
                </Row>
                </>
            }
        </Container>
    )
}

export default OrderDispatch;