import React from "react";
import clsx from "clsx";
import Proptypes from "prop-types";

const Button = (props) => {
    const {type, className, styleInput, onClickInput, contentButton, disabled, title} = props;
    return (
        <button type={type}
            className={clsx('btn',className)}
            style={styleInput}
            onClick={onClickInput}
            disabled={(disabled!==undefined&&disabled!==null)?disabled:false}
            title={title}
        >
            {contentButton}
        </button>
    );
}

Button.propTypes = {
    type: Proptypes.string.isRequired,
    className: Proptypes.string,
    styleInput: Proptypes.object,
    onClickInput: Proptypes.func.isRequired,
    contentButton: Proptypes.node.isRequired,
    disabled: Proptypes.bool,
    title: Proptypes.string
}

export default Button;