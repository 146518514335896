import React,{useEffect, useState} from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faClose, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2';
// const data = [
//     {id:1,name: 'Miguel Peralta',document: '031654875',choose:<FontAwesomeIcon icon={faArrowRightLong} />},
//     {id:2,name: 'Juan Peralta',document: '031654887',choose:<FontAwesomeIcon icon={faArrowRightLong} />},
//     {id:3,name: 'Pedro Peralta',document: '031654805',choose:<FontAwesomeIcon icon={faArrowRightLong} />},
//     {id:4,name: 'Ines Peralta',document: '031654854',choose:<FontAwesomeIcon icon={faArrowRightLong} />},
//     {id:5,name: 'Peter Peralta',document: '031654665',choose:<FontAwesomeIcon icon={faArrowRightLong} />}
// ];
const columns = [
    {
        name: 'SELECCIONAR',
        selector: row => row.chooseIcon,
        center: true
    },
    {
        name: 'CÓDIGO',
        selector: row => row.product_code,
        sortable: true,
        center: true
    },
    {
        name: 'PRODUCTO',
        selector: row => row.product_des,
        sortable: true,
        center: true
    },
    {
        name: 'CATEGORÍA',
        selector: row => row.sub_category_des,
        sortable: true,
        center: true
    },
    {
        name: 'PRECIO',
        selector: row => row.total_price_formated,
        right: true
    },
    {
        name: 'INVENTARIO',
        selector: row => row.use_inventory_label,
        center: true
    }    
];
const paginationOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos'
};

const ItemDataTable = (props) => {
    const [dataTableData,setDataTableData] = useState([]),
        [loadingTable,setLoadingTable] = useState(false),
        [criteria,setCriteria] = useState('');
    useEffect(()=>{        
        searchItemData();
    },[]);
    useEffect(()=>{
        if(criteria.length<1){
            search();
        }
    },[criteria]);
    const keyChangeCriteria = (input) => {
        const value = input.target.value;
        setCriteria(value);
    }
    const onKeyUpCriteria = (input) => {
        if(input.key=='Enter'){
            search();
        }
    }
    const search = async () => {
        if(criteria)
        {
            setLoadingTable(true);
            try{
                // eslint-disable-next-line react/prop-types
                const path = props.isMaterial ? 'get/datatable/materials/by/criteria' : 'get/datatable/products/by/criteria';
                // eslint-disable-next-line no-undef
                const resource = await axios.post(process.env.REACT_APP_PATH_API + path,{criteria: criteria,token: localStorage.getItem('OSHEN-LOC-STR-TK')}),
                    data = resource.data;            
                for(let i=0;i<data.length;i++){
                    data[i].chooseIcon = <FontAwesomeIcon id={parseInt(data[i].product_id)} icon={faCheckCircle} onClick={selectItem} style={{fontSize: '18px',fontWeight: 'bold',cursor: 'pointer', color: "#15a513"}} />
                }
                setDataTableData(data);
                setLoadingTable(false);
            } catch(error){
                setLoadingTable(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Error interno',
                    text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
                });
            }
        }
    }
    const selectItem = async (input) => {
        const id = input.currentTarget.getAttribute('id');
        try{
            // eslint-disable-next-line react/prop-types
            const path = props.isMaterial ? 'get/material/by/id' : 'get/product/by/id';
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + path,{id:id}),
                data = await resource.data;
            // eslint-disable-next-line react/prop-types
            //checkSupplierValues = props.isMaterial&&data.product_id>0 ? {value:parseInt(data.objPricing.supplier_id),label:data.objPricing.supplier_name} : {};
            let pricingArr = [];
            if(data.objPricing){
                for(let cnt=0;cnt<data.objPricing.length;cnt++){
                    const OPA = data.objPricing[cnt],
                        pricingArrData = {
                            id: parseInt(OPA.id),
                            currency: parseInt(OPA.currency_id),
                            price:parseFloat(OPA.total_price),
                            unit:parseInt(OPA.unit_id),
                            supplierValues:{}
                        };
                    pricingArr.push(pricingArrData);
                }
            }
            const product = {
                id: parseInt(data.product_id),
                code: data.product_code,
                description: data.product_des,
                subCategory: parseInt(data.sub_category_id),
                inventory: data.use_inventory=='t'?true:false,
                isCosting: data.is_costing,
                file: data.path_file,
                pricing: pricingArr/*[{
                    id: parseInt(data.objPricing.id),
                    currency: parseInt(data.objPricing.currency_id),
                    price:parseFloat(data.objPricing.total_price),
                    unit:parseInt(data.objPricing.unit_id),
                    supplierValues:checkSupplierValues
                }]*/,
                taxes: data.arrTaxes,
                inventories: [{
                    id: data.objInventory!==null ? parseInt(data.objInventory.id) : 0,
                    location: data.objInventory!==null ? parseInt(data.objInventory.location_id) : 0,
                    qty: data.objInventory!==null ? parseFloat(data.objInventory.product_inventory_qty) : 0,
                    unit: data.objInventory!==null ? parseInt(data.objInventory.unit_id) : 0
                }],
                discounts: {
                    id: data.objDiscount!==null ? parseInt(data.objDiscount.id) : 0,
                    currency: data.objDiscount!==null ? parseInt(data.objDiscount.currency_id) : 0,
                    percent: data.objDiscount!==null ? parseFloat(data.objDiscount.percent) : 0,
                    amount: data.objDiscount!==null ? parseFloat(data.objDiscount.amount) : 0
                }
            };
            // eslint-disable-next-line react/prop-types
            props.handleProduct(product);
            // eslint-disable-next-line react/prop-types
            props.handlePopUp(false);
            // eslint-disable-next-line react/prop-types
            props.setExtraPricing(pricingArr);
        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    const searchItemData = async() => {
        setLoadingTable(true);
        try{
            // eslint-disable-next-line react/prop-types
            const path = props.isMaterial ? 'get/datatable/materials' : 'get/datatable/products';
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + path,{token: localStorage.getItem('OSHEN-LOC-STR-TK')}),
                data = resource.data;
            for(let i=0;i<data.length;i++){
                data[i].chooseIcon = <FontAwesomeIcon id={parseInt(data[i].product_id)} icon={faCheckCircle} onClick={selectItem} style={{fontSize: '18px',fontWeight: 'bold',cursor: 'pointer', color: "#15a513"}} />
            }
            setDataTableData(data);
            setLoadingTable(false);
        } catch(error){
            setLoadingTable(false);
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-4'>
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" placeholder="Criterio de busqueda" onChange={keyChangeCriteria} onKeyUp={onKeyUpCriteria} value={criteria} />
                        <span className="input-group-text btn btn-info text-white" onClick={search}><FontAwesomeIcon icon={faSearch} /></span>
                        {
                            criteria.length?
                                <span className="input-group-text btn btn-secondary text-white" onClick={()=>{setCriteria('');searchItemData()}}><FontAwesomeIcon icon={faClose} /></span>
                            :null
                        }                        
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12 table-responsive'>
                    <DataTable 
                        columns={columns}
                        data={dataTableData}
                        // eslint-disable-next-line react/prop-types
                        title="Listado de producto"
                        pagination
                        paginationComponentOptions={paginationOptions}
                        fixedHeader
                        fixedHeaderScrollHeight='600'
                        progressPending={loadingTable}
                    />
                </div>
            </div>        
        </div>
    )
}

export default ItemDataTable;