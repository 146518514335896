import axios from "axios";
import React, {useState,useRef} from "react";
import { Modal,Button,Spinner } from "react-bootstrap";
// import DateTimePicker from 'react-datetime-picker';
import Swal from "sweetalert2";
const defaultTask = {
    id:0,
    description: "",
    department: 0,
    priority: 0,
    detail: "",
    attach: null
}
const TaskForm = (props) => {
    const [dateFilter,setDateFilter] = useState(new Date()),
        [isLoading,setIsLoading] = useState(false),
        [task,setTask] = useState(defaultTask),
        // eslint-disable-next-line no-unused-vars
        [tmpImage,setTmpImage] = useState(null),
        fileInputRef = useRef(),
        depRef = useRef()/*,
        condRef = useRef()*/;
    const clearInputs = () => {
        setTask(defaultTask);
        setDateFilter(new Date());
        fileInputRef.current.value = null;
        depRef.current.value = defaultTask.department;
        // condRef.current.value = defaultTask.priority;
    }
    const setter = (input) => {
        const key = input.target.id,
            value = input.target.value;
        setTask({
            ...task,
            [key]: value
        })
    }
    const onChangeFileInput = (evn) => {
        const file = evn.target.files[0],
            reader = new FileReader(),
            // eslint-disable-next-line no-unused-vars
            url = reader.readAsDataURL(file);
        setTask({
            ...task,
            attach: file
        });
        reader.onloadend = () => {
            setTmpImage(reader.result);
        }
    }
    const save = async () => {        
        const dF = new Date(dateFilter),
        date = dF.getFullYear() + '-' + (dF.getMonth()+1) + '-' + dF.getDate();
        const formData = new FormData();
        if(task.attach!=undefined)
            formData.append('attach',task.attach,task.attach.name);

        formData.append('description',task.description);
        formData.append('date',date);
        formData.append('department',task.department);
        formData.append('priority',task.priority);
        formData.append('detail',task.detail);

        formData.append('token',localStorage.getItem('OSHEN-LOC-STR-TK'));

        // eslint-disable-next-line react/prop-types
        formData.append('viewer',props.viewer);

        setIsLoading(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'put/tasks',formData),
            data = await resource.data;
            setIsLoading(false);
            // eslint-disable-next-line no-prototype-builtins
            if(data!=undefined&&data.log==false){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                return false;
            }
            if(data.response==false){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                return false;
            }
            Swal.fire({
                icon: 'success',
                title: 'Operación completada',
                text: data.msg
            }).then(()=>{
                // eslint-disable-next-line react/prop-types
                props.setInfo({
                    // eslint-disable-next-line react/prop-types
                    ...props.info,
                    tasks: data.tasks
                });
                // eslint-disable-next-line react/prop-types
                props.setLastTasks(data.lastTasks);
                clearInputs();
            });
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error interno, favor contactar al administrador'
            });
        }
    }
    return (
        // eslint-disable-next-line react/prop-types
        <Modal show={props.show} backdrop="static" onHide={()=>{clearInputs();props.handle(false)}}>
            <Modal.Header className='custom-modal-header-color' closeButton>
                <Modal.Title className=''>Tarea</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <label className="form-label">Descripción <span style={{color: '#f00'}}>*</span></label>
                            <input type="text" className="form-control" id="description" onChange={setter} value={task.description} />
                            <div className="form-text">Titulo de la tarea a realizar</div>
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className="col-6 mb-3">
                            <label className="form-label">Fecha de inicio</label><br />
                            <DateTimePicker
                                onChange={setDateFilter}
                                value={dateFilter}
                                format="dd-MM-y"
                                disableClock={true}
                            />
                        </div> */}
                        <div className="col-12 mb-3">
                            <label className="form-label">Departamento <span style={{color: '#f00'}}>*</span></label>
                            <select className="form-select" defaultValue={task.department} id="department" ref={depRef} onChange={setter}>
                                <option value={0}>==========</option>
                                {
                                    // eslint-disable-next-line react/prop-types
                                    props.departments.map((elm,idx)=>{
                                        return <option key={idx} value={elm.id}>{elm.description}</option>
                                    })
                                }
                            </select>
                        </div>
                        {/* <div className="col-6 mb-3">
                            <label className="form-label">Seleccionar</label>
                            <select className="form-select" defaultValue={task.priority} id="priority" ref={condRef} onChange={setter}>
                                <option value={0}>Prioridad</option>
                                {
                                    // eslint-disable-next-line react/prop-types
                                    props.priorities.map((elm,idx)=>{
                                        return <option key={idx} value={elm.id}>{elm.description}</option>
                                    })
                                }
                            </select>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <label className="form-label">¿Cual es tu situación?<span style={{color: '#f00'}}>*</span></label>
                            <textarea className="form-control" id="detail" rows="3" onChange={setter} value={task.detail}></textarea>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <label className="form-label">Adjuntar un archivo(.pdf, .xls, .png, .jpg, .jpeg)</label>
                            <input className="form-control" type="file" id="attachFile" onChange={onChangeFileInput} ref={fileInputRef} />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* eslint-disable-next-line react/prop-types*/}
                <Button variant="secondary" onClick={()=>{clearInputs();props.handle(false)}}>
                    Cerrar
                </Button>
                {
                    !isLoading ? 
                        <Button variant="primary" onClick={save}>
                            {task.id>0?'Actualizar':'Guardar'}
                        </Button>
                    :
                        <Button variant="primary" disabled>
                            <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            />
                            Loading...
                        </Button>
                }
            </Modal.Footer>
        </Modal>        
    )
}

export default TaskForm;