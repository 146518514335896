import React, {useState,useRef,useEffect} from 'react';
import {Modal,Button,Popover,OverlayTrigger,/*Accordion,*/Spinner,Badge} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faQuestionCircle, faTimes} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Swal from 'sweetalert2';
import PopUp from '../Layouts/popUp';
import ItemDataTable from '../Layouts/itemDataTable';
import FormGroup from './atoms/FormGroup';
import SelectGroup from './atoms/SelectGroup';
import PropTypes from 'prop-types';
import { nFormat } from '../Vendor/common';
// import ImageCrop from './atoms/Crop';
// import Select from 'react-select';
const popover = (
    <Popover>
      <Popover.Header as="h3">Información de código</Popover.Header>
      <Popover.Body>
        Si tienes configurada la codificación de producto, no será necesario ingresar un valor.
      </Popover.Body>
    </Popover>
  );
const defaultPricing = {
    id: 0,
    currency:0,
    price:0,
    unit:0
};
const defaultInventories = {
    id: 0,
    location: 0,
    qty: 0,
    unit: 0
}
const defaultDiscount = {
    id: 0,
    currency: 0,
    percent: 0,
    amount: 0
}
const defaultProduct = {
    id: 0,
    code: '',
    description: '',
    subCategory: 0,
    inventory: false,
    pricing: [defaultPricing],
    taxes: [],
    inventories: [defaultInventories],
    discounts: defaultDiscount,
    image: null,
    isCosting: 'f'
}
const ModalProducts = (props) => {
const {params, isMaterial, itemProductInfo/*, suppliers*/, fromPos} = props;
    const [product,setProduct] = useState(defaultProduct),
        // eslint-disable-next-line no-unused-vars
        [isLoading,setIsLoading] = useState(false),
        [isLoadingRemoved,setIsLoadingRemoved] = useState(false),
        [showPopUp,setShowPopUp] = useState(false),
        [tmpImage,setTmpImage] = useState(null),
        // [supplierSelected,setSupplierSelected] = useState({value: 0, label: ''}),
        taxRef = useRef([]),
        inputProductFile = useRef(),
        
        // previewCanvasRef = useRef(),
        imgRef = useRef(),
        // [completedCrop, setCompletedCrop] = useState()
        [extraPricing,setExtraPricing] = useState([]),
        usePriceRef = useRef(),
        usePriceUnitRef = useRef(),
        usePriceItbis = useRef()
        ;

    useEffect(()=>{
        if(
            parseInt(product.id)>0
            &&
            product.taxes.length
            &&
            taxRef.current!=undefined
            )
        {
            // taxRef.current.checked = true;
            taxRef.current.map((element,index)=>{
                for(let counter=0;counter<product.taxes.length;counter++){
                    const id = product.taxes[counter];
                    if(id==element.getAttribute('id')){
                        taxRef.current[index].checked = true;
                    }
                }
            });            
        } 
        else 
        {
            if(product.taxes.length<1&&taxRef.current!=undefined)
            {
                // taxRef.current.checked = false;
                taxRef.current.map((element,index)=>{
                    taxRef.current[index].checked = false;
                });
            }
        }
        // if(parseInt(product.id)>0&&product.pricing){
        //     setSupplierSelected(product.pricing[0].supplierValues);
        // }
        // if(parseInt(product.id)>0&&product.pricing){
        //     let arr = [...extraPricing];
        //     product.pricing.map((pricingElement)=>{
        //         arr.push(pricingElement);
        //     });
        //     setExtraPricing(arr);
        // }
    },[product]);
    useEffect(()=>{
        if(itemProductInfo!==null&&itemProductInfo!==undefined){
            setProduct(itemProductInfo);
            setExtraPricing(itemProductInfo.pricing);
        }
    },[itemProductInfo]);
    const handleData = (input) => {
        const key = input.target.id,
            value = input.target.value;
        setProduct({
            ...product,
            [key] : value
        });
    }
    const handleInventoryCheckBox = (input) => {
        const value = input.target.checked;
        setProduct({
            ...product,
            inventory: value
        })
    }
    // eslint-disable-next-line no-unused-vars
    const handlePricingData = (input) => {
        const idx = input.target.getAttribute('idx'),
            key = input.target.getAttribute('keyid'),
            value = input.target.value;
        let pricing =[...product.pricing],
            obj = pricing[idx];
        //default currency
        obj = {
            ...obj,
            // eslint-disable-next-line react/prop-types
            currency: props.defaultCurrency
        }
        switch(key)
        {
            case "currency":
                obj = {
                    ...obj,
                    currency: value
                };
                break;
            case "price":
                obj = {
                    ...obj,
                    price: value
                };
                break;
            case "unit":
                obj = {
                    ...obj,
                    unit: value
                };
                break;
        }
        pricing[idx] = obj;
        setProduct({
            ...product,
            pricing: pricing
        });
    }
    const handleInventoriesData = (input) => {
        const idx = input.target.getAttribute('idx'),
            key = input.target.getAttribute('keyid'),
            value = input.target.value;
        let inventories =[...product.inventories],
            obj = inventories[idx];
        switch(key)
        {
            case "location":
                obj = {
                    ...obj,
                    location: value
                };
                break;
            case "qty":
                obj = {
                    ...obj,
                    qty: value
                };
                break;
            case "unit":
                obj = {
                    ...obj,
                    unit: value
                };
                break;
        }
        inventories[idx] = obj;
        setProduct({
            ...product,
            inventories: inventories
        });
    }
    const handleDiscountData = (input) => {
        const key = input.target.getAttribute('keyid'),
            value = input.target.value;
        let discount = {}
        switch(key)
        {
            case "percent":
                discount = {
                    ...product.discounts,
                    percent: value,
                    // eslint-disable-next-line react/prop-types
                    currency: props.defaultCurrency
                };
                break;
            case "amount":
                discount = {
                    ...product.discounts,
                    amount: value,
                    // eslint-disable-next-line react/prop-types
                    currency: props.defaultCurrency
                };
                break;
        }
        setProduct({
            ...product,
            discounts: discount
        });
    }
    const handleTaxDate = (input) => {
        const id = parseInt(input.target.getAttribute('id')),
            value = input.target.checked;
        let arr = [...product.taxes];
        if(value){
            if(arr.indexOf(id)<0){
                arr.push(id);
                setProduct({
                    ...product,
                    taxes: arr
                });
            }
        } else {
            const arrIdx = arr.indexOf(id);
            if(arrIdx>=0){
                arr.splice(arrIdx,1);
                setProduct({
                    ...product,
                    taxes: arr
                });
            }
        }        
    }
    const onChangeImage = (evn) => {
        const file = evn.target.files[0],
            reader = new FileReader(),
            // eslint-disable-next-line no-unused-vars
            url = reader.readAsDataURL(file);
        setProduct({
            ...product,
            image: file
        });
        reader.onloadend = () => {
            setTmpImage(reader.result);
        }
    }
    const clearProduct = () => {
        setProduct(defaultProduct);
        inputProductFile.current.value = null;
        setTmpImage(null);
        setExtraPricing([]);
    }
    // const addNewPrice = (e) => {
    //     e.preventDefault();
    //     let pricing = product.pricing;
    //     pricing.push(defaultPricing);
    //     setProduct({
    //         ...product,
    //         pricing: pricing
    //     });
    // }
    // const addNewLocation = (e) => {
    //     e.preventDefault();
    //     let inventories = product.inventories;
    //     inventories.push(defaultInventories);
    //     setProduct({
    //         ...product,
    //         inventories: inventories
    //     });
    // }
    const save = async () => {
        const defineFromSale = fromPos!==undefined?fromPos:false;
        setIsLoading(true);
        try{
            const formData = new FormData();

            if(product.image!=undefined)
                formData.append('itemImage',product.image,product.image.name);

            formData.append('data',JSON.stringify(product));

            formData.append('isMaterial',isMaterial?1:0);

            // formData.append('supplier',JSON.stringify(supplierSelected));

            formData.append('token',localStorage.getItem('OSHEN-LOC-STR-TK'));

            // eslint-disable-next-line react/prop-types
            formData.append('defineCurrency',props.defaultCurrency);

            formData.append('extraPricing',JSON.stringify(extraPricing));

            formData.append('defineFromSale',defineFromSale);

            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'save/merchandise',formData),
                data = await resource.data;
            if(data.status){
                setIsLoading(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Éxito',
                    text: data.msg
                }).then(()=>{
                    // location.reload();
                    /* eslint-disable-next-line react/prop-types */
                    props.handleProducts(data.products);
                    clearProduct();
                });
            // eslint-disable-next-line no-unreachable
            } else {
                setIsLoading(false);
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
            }
        // eslint-disable-next-line no-unreachable
        } catch(error){
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    const removeProduct = async () => {
        setIsLoadingRemoved(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'remove/merchandise',{product: product.id,token: localStorage.getItem('OSHEN-LOC-STR-TK')}),
            data = await resource.data;
            if(data.status){
                setIsLoadingRemoved(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Éxito',
                    text: data.msg
                }).then(()=>{
                    location.reload();
                });
            } else {
                setIsLoadingRemoved(false);
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
            }
        } catch(error){
            setIsLoadingRemoved(false);
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }    
    const addExtraPricing = () => {        
        const priceProduct = parseFloat(usePriceRef.current.value),
              priceUnitProduct = parseInt(usePriceUnitRef.current.value);

        if(isNaN(priceProduct)){
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Debe ingresar un precio mayor que 0'
            });
            return;
        }
        if(!isNaN(priceProduct))
        {
            //const pPricing = product.pricing[0];
            const pPricing = {
                id: 0,
                currency:isMaterial?parseInt(params.defaultCurrencyBuy):parseInt(params.defaultCurrencySell),
                price:priceProduct,
                unit:priceUnitProduct
            };
            if(parseFloat(pPricing.price)<=0){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'Debe ingresar un precio mayor que 0'
                });
                return;
            }
            if(parseFloat(pPricing.unit)<1){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'Debe seleccionar al menos una unidad'
                });
                return;
            }
            for(let c=0;c<extraPricing.length;c++){
                const extP = extraPricing[c];
                if(extP.unit==pPricing.unit){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Advertencia',
                        text: 'Existe un precio con esta unidad en la lista'
                    });
                    return;
                }
            }
            let arr = [...extraPricing];
            arr.push(pPricing);
            setExtraPricing(arr);
            usePriceRef.current.value = '';
            usePriceItbis.current.value = '';
            usePriceUnitRef.current.value = 0;            
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Precio no encontrado'
            });
        }        
    }
    const removeExtraPricing = (btn) => {
        let id = btn.target.getAttribute('id'),
            arr = [...extraPricing];
        arr.splice(id,1);
        setExtraPricing(arr);
    }
    const getDescriptionByUnit = (id) => {
        if(Object.keys(params).length>0){
            for(let countUnit = 0; countUnit < params.units.length; countUnit++){
                const obj = params.units[countUnit];
                if(obj.value == id){
                    return obj.description;
                }
            }
        }
        return "";
    }
    return (
        <>
        {/* eslint-disable-next-line react/prop-types */}
        <Modal fullscreen={true} show={props.show} onHide={()=>{clearProduct();props.handle(false)}}>
            <Modal.Header className='custom-modal-header-color' closeButton>
                <Modal.Title className=''>Producto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='container-fluid'>
                    <div className='row' style={{marginBottom: '15px'}}>
                        <div className='col-md-12'>
                            <Button variant="primary" style={{fontSize: '14px',color: '#fff'}} onClick={()=>setShowPopUp(true)}>Actualizar producto</Button>
                            {
                                parseInt(product.id)>0?
                                    <Button variant="secondary" style={{fontSize: '14px',color: '#fff',marginLeft: '5px'}} onClick={clearProduct}>Limpiar producto</Button>                                    
                                :null                                
                            }
                            {
                                parseInt(product.id)>0?
                                    !isLoadingRemoved?
                                    <Button variant="danger" style={{fontSize: '14px',color: '#fff',marginLeft: '5px'}} onClick={removeProduct}>Eliminar producto</Button>
                                    : <Button variant="danger" disabled>
                                        <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        />
                                        Loading...
                                    </Button>
                                :null
                            }
                        </div>
                    </div>                    

                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='row'>                                
                                <FormGroup 
                                    id='code'
                                    onChange={handleData} 
                                    value={product.code} 
                                    className='col-4' 
                                    inputType='text' 
                                    label={(
                                        <>
                                            Código <span style={{color: "#f00"}}>*</span>
                                            <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                                <Button variant="default" style={{fontSize: '14px',padding: '0px 5px',color: '#198754'}}>
                                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                                </Button>
                                            </OverlayTrigger>
                                        </>
                                    )} 
                                    inputName='code'
                                />                                
                                <FormGroup 
                                    id='description'
                                    onChange={handleData} 
                                    value={product.description} 
                                    className='col-8' 
                                    inputType='text' 
                                    label={(
                                        <>
                                            Nombre de producto <span style={{color: "#f00"}}>*</span>
                                        </>
                                    )} 
                                    inputName='description'
                                />
                            </div>
                            <div className='row'>
                                <SelectGroup 
                                    onChange={handleData} 
                                    value={product.subCategory} 
                                    className="col-4" 
                                    label={
                                        <>
                                            Sub-categoría <span style={{color: "#f00"}}>*</span>
                                        </>
                                    }
                                    inputName="subCategory" 
                                    id="subCategory"
                                    options={(Object.keys(params).length>0)?params.subCategories:[]}
                                />
                            </div>
                            <div className='row'>
                                <div className='col-6 mb-3'>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="inventory" name="inventory" onChange={handleInventoryCheckBox} checked={product.inventory} />
                                        <label className="form-check-label">Controlar inventario</label>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>                        
                                <div className='col-12 mb-3'>
                                    {/* eslint-disable-next-line react/prop-types */ }
                                    <h6 style={{color: '#518de5',fontWeight: 'bold',borderTop: '5px solid #b5b5b5',paddingTop: '15px'}}>Ingresa el {isMaterial?'costo':'precio'} del producto({props.defaultCurrencyLabel})</h6>
                                    {
                                        // isMaterial?
                                        //     <div className='row'>
                                        //         <div className="col-md-8" style={{marginBottom: '5px'}}>
                                        //             <label style={{marginBottom: '6px'}}>Proveedor</label>
                                        //             <Select
                                        //                 options={suppliers}                         
                                        //                 className="form-control form-select-sm" 
                                        //                 value={supplierSelected}
                                        //                 defaultValue={supplierSelected} 
                                        //                 onChange={setSupplierSelected}
                                        //             />
                                        //         </div>
                                        //     </div>
                                        // :null
                                    }
                                    {
                                        <div className='row'>
                                            <FormGroup 
                                                keyRef={usePriceItbis}
                                                onChange={(input)=>{
                                                    const priceItbis = parseFloat(input.currentTarget.value),
                                                          calcPriceItbis = parseFloat( (priceItbis/1.18) ).toFixed(2);
                                                          usePriceRef.current.value = calcPriceItbis;
                                                }}
                                                className='col-3' 
                                                inputType='number' 
                                                label="Precio + ITBIS" 
                                                inputName='price_itbis'
                                            /> 
                                            <FormGroup 
                                                keyRef={usePriceRef}
                                                //onChange={handlePricingData}
                                                onChange={()=>{}}
                                                //value={pElement.price} 
                                                className='col-3' 
                                                inputType='number' 
                                                label={<>{isMaterial?'Costo':'Precio'} <span style={{color: "#f00"}}>*</span></>} 
                                                inputName='price'
                                            />                                                    
                                            <SelectGroup 
                                                keyRef={usePriceUnitRef}                                                        
                                                //onChange={handlePricingData} 
                                                onChange={()=>{}}
                                                //value={pElement.unit} 
                                                className="col-3" 
                                                label={
                                                    <>
                                                        Unidad <span style={{color: "#f00"}}>*</span>
                                                    </>
                                                }
                                                inputName="unit" 
                                                id="unit"
                                                options={(Object.keys(params).length>0)?params.units:[]}
                                            />
                                            <div className='col-3 form-group'>
                                                <label style={{color: '#fff'}}>-</label><br />
                                                <Button variant="success" style={{fontSize: '14px',color: '#fff',marginTop: '3%',height: '50%'}} onClick={addExtraPricing} title="Agregar a lista de precios"><FontAwesomeIcon icon={faPlus} /> Agregar</Button>
                                            </div>
                                        </div>
                                        /*product.pricing.map((pElement,pIndex)=>{
                                            return (
                                                <div className='row' key={pIndex}>
                                                    <FormGroup 
                                                        index={pIndex}
                                                        keyRef={usePriceRef}
                                                        //onChange={handlePricingData}
                                                        onChange={()=>{}}
                                                        //value={pElement.price} 
                                                        className='col-4' 
                                                        inputType='number' 
                                                        label={<>{isMaterial?'Costo':'Precio'} <span style={{color: "#f00"}}>*</span></>} 
                                                        inputName='price'
                                                    />                                                    
                                                    <SelectGroup 
                                                        keyRef={usePriceUnitRef}                                                        
                                                        //onChange={handlePricingData} 
                                                        onChange={()=>{}}
                                                        //value={pElement.unit} 
                                                        className="col-4" 
                                                        label={
                                                            <>
                                                                Unidad <span style={{color: "#f00"}}>*</span>
                                                            </>
                                                        }
                                                        inputName="unit" 
                                                        id="unit"
                                                        index={pIndex}
                                                        options={(Object.keys(params).length>0)?params.units:[]}
                                                    />
                                                    <div className='col-4'>                                                        
                                                        <Button variant="success" style={{fontSize: '14px',color: '#fff',marginTop: '8%'}} onClick={addExtraPricing}><FontAwesomeIcon icon={faPlus} /> Agregar precio a la lista</Button>
                                                    </div>
                                                </div>
                                            )
                                        })*/
                                    }                                    
                                    <div className='row'>
                                        <div className='col-12 mb-3'>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th>PRECIO</th>
                                                        <th>UNIDAD</th>
                                                        <th>REMOVER</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        extraPricing!==null?
                                                        extraPricing.map((extraElement,extraIdx)=>{
                                                            return <tr key={extraIdx}>
                                                                        <td>${nFormat(parseFloat(extraElement.price).toFixed(2))}</td>
                                                                        <td>{getDescriptionByUnit(extraElement.unit)}</td>
                                                                        <td><Button variant="danger" id={extraIdx} style={{fontSize: '14px',color: '#fff'}} onClick={removeExtraPricing}><FontAwesomeIcon icon={faTimes} /></Button></td>
                                                                    </tr>
                                                        })
                                                    :null
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className='row'>                        
                            <div className='col-12 mb-3'>
                                <h6 style={{color: '#518de5',fontWeight: 'bold',borderTop: '5px solid #b5b5b5',paddingTop: '15px'}}>Registra los Impuestos del producto</h6>
                                <ul className="list-group">
                                    {
                                        // eslint-disable-next-line react/prop-types
                                            (Object.keys(props.params).length>0) ?
                                            // eslint-disable-next-line react/prop-types
                                            props.params.taxes.map((element,index)=>{
                                                // let taxOptionSelected = false;
                                                // for(let counter=0;counter<product.taxes;counter++){
                                                //     if(parseInt(product.taxes[counter])==parseInt(element.tax_id)){
                                                //         taxOptionSelected = true;
                                                //         break;
                                                //     }                                                                
                                                // }
                                                return (
                                                    <li key={'list-option-'+index} className="list-group-item">
                                                        <div className="form-check form-switch">
                                                            {/* ref={taxOptionSelected?taxRef:null} */}
                                                            <input className="form-check-input" type="checkbox" role="switch" idx={index} id={element.tax_id} onChange={handleTaxDate} ref={(element) => {taxRef.current[index] = element}} />
                                                            <label className="form-check-label">{element.tax_des} ({element.tax_percent})</label>
                                                        </div>
                                                    </li>
                                                )                                                                                                                                                                        
                                            })
                                        : null
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 mb-3'>
                                <h6 style={{color: '#518de5',fontWeight: 'bold',borderTop: '5px solid #b5b5b5',paddingTop: '15px'}}>¿El producto maneja inventario?, <small className='text-muted'>Este solo será el inventario inicial, si deseas agregar un inventario adicional utiliza el modulo de compra o ajuste de inventario...</small></h6>
                                {
                                    product.inventories.map((iElement,iIndex)=>{
                                        return (
                                            <div className='row' key={iIndex}>
                                                <div className='col-4 mb-3'>
                                                    <label className='form-label'>Localidad <span style={{color: "#f00"}}>*</span></label><br />
                                                    <select 
                                                        idx={iIndex} 
                                                        keyid="location" 
                                                        className="form-select form-select-md" 
                                                        onChange={handleInventoriesData} 
                                                        value={iElement.location}
                                                        >
                                                        <option key={'option-0'} value={0}>Seleccionar</option>
                                                        {
                                                            // eslint-disable-next-line react/prop-types
                                                            (Object.keys(props.params).length>0) ?
                                                                // eslint-disable-next-line react/prop-types
                                                                props.params.locations.map((element,index)=>{
                                                                    return <option key={'option-'+index} value={element.location_id}>{element.location_des}</option>
                                                                })
                                                            : null
                                                        }
                                                    </select>
                                                </div>
                                                <div className='col-4 mb-3'>
                                                    <label className='form-label'>Cantidad <span style={{color: "#f00"}}>*</span></label>
                                                    <input type="number" idx={iIndex} keyid="qty" className="form-control form-control-md" onChange={handleInventoriesData} value={iElement.qty} />
                                                </div>
                                                {/* <div className='col-4 mb-3'>
                                                    <label className='form-label'>Unidad <span style={{color: "#f00"}}>*</span></label><br />
                                                    <select idx={iIndex} keyid="unit" className="form-select form-select-lg" onChange={handleInventoriesData} value={iElement.unit}>
                                                        <option key={'option-0'} value={0}>Seleccionar</option>
                                                        {
                                                            // eslint-disable-next-line react/prop-types
                                                            (Object.keys(props.params).length>0) ?
                                                                // eslint-disable-next-line react/prop-types
                                                                props.params.units.map((element,index)=>{
                                                                    return <option key={'option-'+index} value={element.unit_id}>{element.unit_des}</option>
                                                                })
                                                            : null
                                                        }
                                                    </select>
                                                </div> */}
                                                <SelectGroup 
                                                    onChange={handleInventoriesData} 
                                                    value={iElement.unit} 
                                                    className="col-4" 
                                                    label={
                                                        <>
                                                            Unidad <span style={{color: "#f00"}}>*</span>
                                                        </>
                                                    }
                                                    inputName="unit" 
                                                    id="unit"
                                                    index={iIndex}
                                                    options={(Object.keys(params).length>0)?params.units:[]}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className='row'>                        
                            <div className='col-12 mb-3'>
                                {/* eslint-disable-next-line react/prop-types */}
                                <h6 style={{color: '#518de5',fontWeight: 'bold',borderTop: '5px solid #b5b5b5',paddingTop: '15px'}}>Ingresa el descuento del producto({props.defaultCurrencyLabel})</h6>
                                <div className='row'>
                                    <div className='col-4 mb-3'>
                                        <label className='form-label'>Por ciento(%) <span style={{color: "#f00"}}>*</span></label>
                                        <input type="number" keyid="percent" className="form-control form-control-md" onChange={handleDiscountData} value={product.discounts.percent} />
                                    </div>
                                    <div className='col-4 mb-3'>
                                        <label className='form-label'>Monto($)</label>
                                        <input type="number" keyid="amount" className="form-control form-control-md" onChange={handleDiscountData} value={product.discounts.amount} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <h6 style={{color: '#518de5',fontWeight: 'bold',borderTop: '5px solid #b5b5b5',paddingTop: '15px'}}>Costeo de producto</h6>
                            </div>                        
                            <SelectGroup 
                                onChange={handleData} 
                                value={product.isCosting} 
                                className="col-4" 
                                label={
                                    <>
                                        Lista de costos / precios <span style={{color: "#f00"}}>*</span>
                                    </>
                                }
                                inputName="isCosting" 
                                id="isCosting"
                                options={[
                                    {value: 'f',description: 'NINGUNO'},
                                    {value: 't',description: 'ÚLTIMO COSTO'},
                                    {value: 'p',description: 'PROMEDIO'},
                                ]}
                            />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='row'>
                            <div className='col-md-12' style={{paddingBottom: '2rem',textAlign: 'right',fontSize: '32px'}}>
                                {
                                    parseInt(product.id)>0? <Badge bg="primary">Actualizando</Badge> : <Badge bg="success">Creando</Badge>
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 mb-3'>
                                <label className="form-label">Imagen de producto(Tamaño Sugerido:500px x 500px)</label>
                                <input className="form-control" type="file" id="item-image" onChange={onChangeImage} ref={inputProductFile} accept="image/*" />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                {
                                    (
                                        (tmpImage!=undefined||tmpImage!=null) &&
                                        (product.file==null||product.file==undefined)
                                    )
                                    ?                 
                                        <img 
                                            ref={imgRef}
                                            src={tmpImage}
                                            className="img-fluid" 
                                            alt="Imagen no encontrada" 
                                            width={500} 
                                            height={500} />
                                        // <ImageCrop 
                                        //     setCompletedCrop={setCompletedCrop}
                                        //     previewCanvasRef={previewCanvasRef}
                                        //     completedCrop={completedCrop}
                                        //     imgRef={imgRef}
                                        //     img={<img 
                                        //             ref={imgRef}
                                        //             src={tmpImage}
                                        //             className="img-fluid" 
                                        //             alt="Imagen no encontrada" 
                                        //             width={720} 
                                        //             height={720}
                                        //             style={{ transform: `scale(${1}) rotate(${0}deg)` }} />} />
                                    : 
                                        // eslint-disable-next-line no-undef
                                        <img src={(product.file!=null||product.file!=undefined)?process.env.REACT_APP_PATH_FILE + 'files/items/' + product.file:'/image_not_found.png'} className="img-fluid" alt="Image not found" width={500} height={500} />                                        
                                }                                    
                            </div>
                        </div>
                        {/* <div className='row'>
                            <div className='col-12'>
                                {
                                    !!completedCrop && (
                                        <canvas
                                            ref={previewCanvasRef}
                                            style={{
                                            border: '1px solid black',
                                            objectFit: 'contain',
                                            width: completedCrop.width,
                                            height: completedCrop.height,
                                            }}
                                        />
                                )}
                            </div>                            
                        </div> */}
                    </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* eslint-disable-next-line react/prop-types*/}
                <Button variant="secondary" onClick={()=>{clearProduct();props.handle(false)}}>
                    Cerrar
                </Button>
                {
                    !isLoading ? 
                        <Button variant="primary" onClick={save}>
                            {product.id>0?`Actualizar producto`:`Guardar producto`}
                        </Button>
                    :
                        <Button variant="primary" disabled>
                            <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            />
                            Loading...
                        </Button>
                }                
            </Modal.Footer>
        </Modal>
        <PopUp 
            show={showPopUp}
            handle={setShowPopUp}
            content={<ItemDataTable 
                        handlePopUp={setShowPopUp} 
                        handleProduct={setProduct}
                        setExtraPricing={setExtraPricing}
                        isMaterial={isMaterial} />}
        />
        </>
    );
}

ModalProducts.propTypes = {
    params: PropTypes.object,
    isMaterial: PropTypes.bool,
    suppliers: PropTypes.array,
    itemProductInfo: PropTypes.any,
    fromPos: PropTypes.bool
}

export default ModalProducts;