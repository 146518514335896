import { faCheckDouble, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, {useEffect} from 'react';
import { useState } from 'react';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
const columns = [
    {
        name: 'CONFIRMAR',
        selector: row => row.chooseIcon,
        center: true
    },
    {
        name: 'CANCELAR',
        selector: row => row.deleteIcon,
        center: true
    },
    {
        name: '#CITA',
        selector: row => row.id,
        sortable: true,
        center: true
    },
    {
        name: 'CLIENTE',
        selector: row => row.customer_name,
        sortable: true,
        center: true
    },
    {
        name: 'CORREO DEL CLIENTE',
        selector: row => row.customer_email,
        center: true
    },
    {
        name: 'TELÉFONO DEL CLIENTE',
        selector: row => row.bp_phone,
        center: true
    },
    {
        name: 'FECHA',
        selector: row => row.a_date,
        sortable: true,
        center: true
    },
    {
        name: 'ENCARGADO',
        selector: row => row.user_name,
        sortable: true,
        center: true
    },
    {
        name: 'ESTATUS',
        selector: row => row.status_des,
        right: true
    }    
];
const paginationOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos'
};
const AppointmentConfirm = (props) => {
    const [gridLoading,setGridLoading] = useState(false),
        [gridData,setGridData] = useState([]);
    useEffect(()=>{
        // eslint-disable-next-line react/prop-types
        let appointments = props.resetAppointmentConfirmation;
        // eslint-disable-next-line react/prop-types
        for(let i=0;i<appointments.length;i++){
            // eslint-disable-next-line react/prop-types
            appointments[i].chooseIcon = <FontAwesomeIcon title="Confirmar cita" id={parseInt(appointments[i].id)} icon={faCheckDouble} onClick={confirmA} style={{fontSize: '14px',fontWeight: 'bold',cursor: 'pointer',color: '#04259f'}} />
            // eslint-disable-next-line react/prop-types
            appointments[i].deleteIcon = <FontAwesomeIcon id={parseInt(appointments[i].id)} icon={faTimes} onClick={props.cancel} style={{fontSize: '14px',fontWeight: 'bold',cursor: 'pointer',color: '#f00'}} />
        }        
        setGridData(appointments);
    // eslint-disable-next-line react/prop-types
    },[props.resetAppointmentConfirmation]);
    useEffect(async()=>{
        setGridLoading(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'appointment/confirm/list',{token: localStorage.getItem('OSHEN-LOC-STR-TK')}),
            data = await resource.data;
            let appointments = data;
            for(let i=0;i<appointments.length;i++){
                // eslint-disable-next-line react/prop-types
                appointments[i].chooseIcon = <FontAwesomeIcon title="Confirmar cita" id={parseInt(appointments[i].id)} icon={faCheckDouble} onClick={confirmA} style={{fontSize: '14px',fontWeight: 'bold',cursor: 'pointer',color: '#04259f'}} />
                // eslint-disable-next-line react/prop-types
                appointments[i].deleteIcon = <FontAwesomeIcon id={parseInt(appointments[i].id)} icon={faTimes} onClick={props.cancel} style={{fontSize: '14px',fontWeight: 'bold',cursor: 'pointer',color: '#f00'}} />
            }
            setGridData(data);
            setGridLoading(false);
        } catch(error){
            setGridLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    },[]);
    const confirmA = (input) => {
        const id = input.currentTarget.getAttribute('id');
        Swal.fire({
            title: '¿Esta seguro de confirmar esta cita?',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
        }).then((result)=>{
            if (result.isConfirmed) {
                confirmAppointment(id);                
            }
        });
    }
    const confirmAppointment = async(id) => {
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'appointment/confirmation',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                data: {id}
            }),
            data = await resource.data;
            if(!data.status){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.message
                });
                return;
            }
            Swal.fire({
                icon: 'success',
                title: 'Operación Exitosa',
                text: data.message
            }).then(()=>{
                // eslint-disable-next-line react/prop-types
                props.setResetAppointmentConfirmation(data.appointmentConfirmation);
            });
        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    return (
        <div className='container-fluid'>
            <div className='row justify-content-center' style={{marginTop: '2.5rem'}}>
                {/* eslint-disable-next-line react/prop-types */}
                <div className='col-12 table-responsive' style={props.styling.headerInfoLabel}>
                    <DataTable 
                        columns={columns}
                        data={gridData}
                        title="Confirmaciones pendientes"
                        pagination
                        paginationComponentOptions={paginationOptions}
                        fixedHeader
                        fixedHeaderScrollHeight='600'
                        // eslint-disable-next-line react/prop-types
                        progressPending={gridLoading}
                    />
                </div>
            </div>
        </div>
    )
}

export default AppointmentConfirm;