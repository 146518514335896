import React from "react";
// import axios from 'axios';
// import Swal from 'sweetalert2';
const BBox = (props) => {    
    // useEffect(async()=>{
    //     try{
    //         // eslint-disable-next-line no-undef
    //         const resource = await axios.post(process.env.REACT_APP_PATH_API+'get/BusinessBox/Info',{token: localStorage.getItem('OSHEN-LOC-STR-TK')}),
    //             data = resource.data;
    //         if(data){
    //             console.log('hola')
    //         }
    //     } catch (error) {
    //         Swal.fire({
    //             icon:'error',
    //             title: 'Error interno',
    //             text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
    //         });
    //     }
    // },[]);
    return (
        <div className='d-flex justify-content-center' style={{marginTop: '1rem'}}>
            <div className='col-12'>
                <div className="card">
                    <div className="card-header">
                        {/* eslint-disable-next-line react/prop-types */}
                        Cierre#{props.businessBox.box_id} | {props.businessBox.bp_name}
                    </div>
                    <div className="card-body">
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-3'>
                                    <span>Nombre de terminal:</span>
                                    {/* eslint-disable-next-line react/prop-types */}
                                    <span style={{marginLeft: '1rem',fontWeight: 'bold'}}>{props.businessBox.terminal_des}</span>                                 
                                </div>
                                <div className='col-3'>
                                    <span>Fecha de inicio:</span>
                                    {/* eslint-disable-next-line react/prop-types */}
                                    <span style={{marginLeft: '1rem',fontWeight: 'bold'}}>{props.businessBox.from_date}</span>
                                </div>
                                <div className='col-3'>
                                    <span>Fecha de cierre:</span>
                                    {/* eslint-disable-next-line react/prop-types */}
                                    <span style={{marginLeft: '1rem',fontWeight: 'bold'}}>{props.businessBox.to_date}</span>
                                </div>
                                <div className='col-3'>
                                    <span>Balance inicial:</span>
                                    {/* eslint-disable-next-line react/prop-types */}
                                    <span style={{marginLeft: '1rem',fontWeight: 'bold'}}>{props.businessBox.open_amount}</span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-3'>
                                    <span>Apertura por:</span>
                                    {/* eslint-disable-next-line react/prop-types */}
                                    <span style={{marginLeft: '1rem',fontWeight: 'bold'}}>{props.businessBox.bp_name}</span>
                                </div>
                                <div className='col-3'>
                                    <span>Cierre por:</span>
                                    {/* eslint-disable-next-line react/prop-types */}
                                    <span style={{marginLeft: '1rem',fontWeight: 'bold'}}>{props.businessBox.bp_name_closer}</span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 table-response'>
                                    <hr />
                                    <table className="table table-striped table-sm table-bordered">
                                        <tbody>
                                            {
                                                // eslint-disable-next-line react/prop-types
                                                props.businessBox.detail!=undefined&&props.businessBox.detail!=null?
                                                // eslint-disable-next-line react/prop-types
                                                props.businessBox.detail.map(({condition_des,amount},idx)=>{
                                                    return (
                                                        <tr key={idx}>
                                                            <td className="textAlignLeft">{condition_des}</td>
                                                            <td className="textAlignRight">{amount}</td>
                                                        </tr>
                                                    )
                                                })
                                                :null                                                    
                                            }
                                            {
                                                // eslint-disable-next-line react/prop-types
                                                props.summary!=undefined&&props.summary!=null?
                                                // eslint-disable-next-line react/prop-types
                                                props.summary.map(({amount},idx)=>{
                                                    return (
                                                        <tr key={idx}>
                                                            <td className="textAlignLeft" style={{fontWeight: 'bold',fontSize: '18px'}}>Total</td>
                                                            <td className="textAlignRight" style={{fontWeight: 'bold',fontSize: '18px'}}>{amount}</td>
                                                        </tr>
                                                    )
                                                })
                                                :null
                                            }                                                    
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <h5>Nota</h5>
                                    <p>
                                        {/* eslint-disable-next-line react/prop-types */}
                                        {props.businessBox.note}
                                    </p>
                                </div>
                            </div>
                            {
                                /* eslint-disable-next-line react/prop-types */
                                props.showDenom?
                                    <div className='row'>
                                        <div className='col-12 table-responsive'>
                                            <table className="table table-striped table-sm table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Denominación</th>
                                                        <th>Valor</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        /* eslint-disable-next-line react/prop-types */
                                                        props.denomClosed.map((denomElement,denomIndex)=>{
                                                            return (
                                                                <tr key={denomIndex}>
                                                                    <td style={{fontWeight: 'bold'}}>{denomElement.denom} * {denomElement.qty}</td>
                                                                    <td style={{fontWeight: 'bold'}}>{denomElement.currency_ref + ' ' + denomElement.sub_total}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }                                            
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                :null
                            }                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BBox;